import React from 'react'

export function QuoteSuccess() {
  return (
    <center className="px-3 mt-3 success">
      <h1 className="pt-3 smalltitle milan-text-primary">Success!</h1>
      <h6 className="mb-3 milan-text-tur">Your request has been submitted.</h6>
      <p>We will be contacting you shortly with more information. During normal business hours you can expect to hear from us in about 15 minutes.</p>
    </center>
  )
}

export default QuoteSuccess