import React, { useState } from 'react'
// import { FaPhoneAlt } from 'react-icons/fa'
// import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link, MILAN_PHONE } from '../../utils'
import { SubMenu, updateMenuExpansion } from '.'

export function Navbar({ menu, navbarSize, isOpen, closeMenu }) {

  const [clicked, setClicked] = useState(false)

  const handleClick = (menuItem) => {
    updateMenuExpansion(menu, menuItem)
    // this will trigger this component to rerender
    setClicked(!clicked)
  }

  return (
    <div className={`navbar__menu ${navbarSize} ${isOpen}`}>
      <ul className="mb-0 d-lg-flex">
        {
          menu.map((menuItem, i) => (
            <li key={i} className="menu__item">
              <div className="menu__item__link__wrapper">
                {/*👇 Main menu links go here */}
                {/* Specials / The Process / Areas / Locations  */}
                <Link
                  to={menuItem.pathname}
                  activeClassName="active-link"
                  partiallyActive={menuItem.pathname === "/" ? false : true}
                  onClick={closeMenu}
                >
                  {menuItem.link}
                </Link>
                {menuItem?.subMenu &&
                  <>
                    {
                      navbarSize === 'navbar__menu--lg' &&
                      <span className="caret__icon caret__icon--down"><FontAwesomeIcon icon={faChevronDown} className='ps-1'/></span>
                    }
                    {
                      navbarSize === 'navbar__menu--sm' &&
                      <span className="caret__icon" onClick={() => handleClick(menuItem)}>
                        {menuItem.expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                      </span>
                    }
                  </>
                }
              </div>
              {
                menuItem?.subMenu &&
                <>
                  {/* menuItem ex: Sepcials -> subMenuItems : {This Month's Specials, Payment Plan} */} {/* Returns <ul></ul> */}
                  <SubMenu
                    {...{ menu, menuItem, navbarSize, closeMenu }}
                    index={i}
                    expanded={menuItem.expanded}
                  />
                </>
              }
            </li>
          )
          )}
      </ul>

      <center className="header-tel d-sm-none pt-5 pb-3">
        <a href={`tel:${MILAN_PHONE}`}>
          <em><FontAwesomeIcon icon={faPhone} /></em><span className="ps-2">1-833-NO-RAZOR</span>
        </a>
      </center>
    </div>
  )
}

export default Navbar
