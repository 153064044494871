import React from "react"
import { Link } from 'gatsby'
import { BA_Preview_Link, BA_BODY_AREAS } from '.'
import { graphql, useStaticQuery } from 'gatsby'

export function BA_Home_Hero() {
	const preview_links_icons = useStaticQuery(graphql`
		query preview_links_icons {
			allFile(
				filter: {sourceInstanceName: {eq: "globalImages"}, relativeDirectory: {eq: "process/beforeafter/home"}}
			) {
				edges {
					node {
						name
						childImageSharp {
							gatsbyImageData(quality: 90, placeholder: BLURRED, webpOptions: {quality: 90})
						}
					}
				}
			}
		}
	`).allFile.edges

	BA_BODY_AREAS.forEach(area => {
		area.preview_link_icon = {}
		area.preview_link_icon.mobile = preview_links_icons.find(node => node.node.name.includes(`${area.area}_Mobile`))
		area.preview_link_icon.desktop = preview_links_icons.find(node => node.node.name.includes(`${area.area}_250`))
	})

	return (
		<section id="beforeafter-home" className="background hero">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-xl-7 m-auto">
						<h1 className="text-center milan-text-primary">Before &amp; After Photos</h1>
						<p className="p-subhead text-center mt-4">From armpits to bikinis and legs to lips, the effectiveness of laser hair removal at Milan is unparalleled.</p>
						<p className="anchor-blue mt-3">We can remove hair from <Link to="/areas/" className="milan-text-primary">anywhere on the body</Link> with our laser, and we can <Link to="/areas/#tone" className="milan-text-primary">treat any skin tone</Link>. Whether you have dark skin or fair, we can adjust our laser to give you the most effective treatment for your complexion.</p>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-lg-8 col-xl-7 mx-auto px-0 d-sm-flex flex-wrap justify-content-center">
						{
							BA_BODY_AREAS.map((area, x) => (
								<BA_Preview_Link key={x} area={area} />
							))
						}
					</div>
				</div>
			</div>
		</section>
	);
}
export default BA_Home_Hero;
