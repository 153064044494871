import React from 'react'
import { Link } from 'gatsby'

export function LocationsBanner({ siteData }) {
  return (
    <section className="divider-section w-100 py-3 py-lg-4 milan-bg-navy">
      <div className="container ">
        <div className="row">
          <div className="col">
            <h3 className="subpointlg milan-text-white text-center mb-0 d-lg-flex align-items-center justify-content-center">
              <span className="d-block">{siteData.milanOpenStores.countToDisplay}+ Locations </span>
              <Link to="/locations/" className="subpointsm arranimate milan-text-secondary ms-lg-3 pt-lg-1">Find The Location Nearest You <span></span></Link>
            </h3>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LocationsBanner