import React from "react";
import { Link } from "gatsby";
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { useGlobalData } from "../../../hooks"
import { TREATMENTS_COUNT_MONTH } from "../../../utils"

export function TreatmentsDivider({ siteData }) {
  const globalData = useGlobalData()
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
        {({ isVisible }) => (
          <div className="container">
            <div className="row align-items-center milan-text-white text-center">
              <div className="col">
                <h3 className="subpointlg">
                  Over <strong><span className="milan-text-tur width-fluid-2">{isVisible ? <CountUp end={globalData.tx_per_month ? globalData.tx_per_month : TREATMENTS_COUNT_MONTH} duration={2} /> : 0},000</span></strong> Treatments
                </h3>
                <p className="p-subhead mb-0 text-capitalize">
                  <span className="d-block d-sm-inline">performed every month at our </span>
                  <Link to="/locations/" className="text-decoration-none">{siteData.milanOpenStores.countToDisplay}+&nbsp;locations</Link>
                </p>
              </div>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </section>
  );
}
export default TreatmentsDivider