import React, { useReducer, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  UserInputs,
  ConsultCheckbox,
  consultSchema,
  reducer,
  submitConsult,
  FORM_ACTIONS,
  FORM_PROGRESS,
  initialFormData,
  initialUserData,
} from '.';
import {
  SELF_SCHEDULE_FORM_HANDLER,
  STANDARD_INQUIRY_FORM_HANDLER,
} from '../../../utils';

export function SingleStore({ siteData }) {
  initialFormData.store = siteData.currentMetro.stores[0];
  initialFormData.metro.name = siteData.currentMetro.name;
  initialFormData.metro.stateAbbrev = siteData.currentMetro.stateAbbrev;
  const [formState, dispatch] = useReducer(reducer, initialFormData);

  const formRef = useRef(null);
  const succesRef = useRef(null);

  const cloudampDataRef = useRef(null);

  //used to capture the cloudamp data value and store it in localStorage
  useEffect(() => {
    // Wait for 3 seconds before accessing the value
    const timer = setTimeout(() => {
      if (cloudampDataRef.current) {
        // Set the value of localStorage to the value cloudampDataRef.current.value
        localStorage.setItem('cloudamp_value', cloudampDataRef.current.value);
      }
    }, 3000); // 3000 milliseconds = 3 seconds
    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id='consult-form' className='hr-divider hr-divider-consult'>
      <h2 className='text-center subheadsm'>Book Your Free Consult</h2>
      <Formik
        initialValues={initialUserData}
        validationSchema={consultSchema}
        onSubmit={() => submitConsult(formState, siteData, succesRef, formRef)}
        validateOnChange={false}
      >
        {
          // This way we can get access to all formik props
          (formik) => {
            return (
              <Form
                className='container'
                ref={formRef}
                action={
                  formState.include.formAction === FORM_ACTIONS.selfSchedule
                    ? SELF_SCHEDULE_FORM_HANDLER
                    : STANDARD_INQUIRY_FORM_HANDLER
                }
                method='POST'
              >
                <input
                  ref={cloudampDataRef}
                  type='hidden'
                  name='cloudamp__data__c'
                />
                <input type='hidden' name='oid' />
                <input
                  id='success'
                  type='hidden'
                  name='retURL'
                  ref={succesRef}
                  value=''
                />

                <input
                  type='hidden'
                  name='city'
                  value={`${formState?.metro?.name}`}
                />
                <input
                  type='hidden'
                  name='state'
                  value={`${formState?.metro?.stateAbbrev}`}
                />

                <input
                  className='lead_source'
                  type='hidden'
                  name='lead_source'
                  id='lead_source'
                  value={formState.include.leadsource}
                />
                <input
                  type='hidden'
                  name='Campaign_ID'
                  id='campaign'
                  value={formState.include.campaignId}
                />
                <UserInputs
                  isQuestion={
                    formState.include.formAction === FORM_ACTIONS.question
                  }
                  formik={formik}
                />
                <input // Location
                  type='text'
                  defaultValue={formState.store.salesforceValue}
                  zip={formState.store.zipCode}
                  className='d-none'
                  id='00N1L00000F9eBV'
                  name='00N1L00000F9eBV'
                  title='Location'
                />

                <div
                  id='actions-btns'
                  className='row gx-1 gy-4 justify-content-center'
                >
                  <div className='col-sm-5 col-lg-4 col-xl-3 text-center'>
                    {
                      // No button clicked yet / Initial setup
                      formState.include.formProgress ===
                      FORM_PROGRESS.initiateQuestion ? (
                        <button
                          type='submit'
                          className='milan-btn milan-navy-btn w-100'
                          disabled={!formik.isValid ? true : false}
                          onClick={() =>
                            dispatch({
                              type: 'formAction',
                              formAction: FORM_ACTIONS.question,
                              formProgress: FORM_PROGRESS.initiateQuestion, // if this set to '' it won't submit
                              user: formik.values,
                            })
                          }
                        >
                          Send Question
                        </button>
                      ) : (
                        <span // Don't use button here
                          className='milan-btn milan-navy-btn w-100'
                          onClick={() =>
                            dispatch({
                              type: 'formAction',
                              formAction: FORM_ACTIONS.question,
                              formProgress: FORM_PROGRESS.initiateQuestion,
                              user: formik.values,
                            })
                          }
                        >
                          Ask A Question
                        </span>
                      )
                    }
                  </div>

                  <div className='d-none d-sm-block col-sm-2 col-lg-1 text-center'>
                    <div className='my-3 my-md-2'>
                      <strong>OR</strong>
                    </div>
                  </div>

                  <div className='col-sm-5 col-lg-4 col-xl-3 text-center'>
                    {formState.include.formProgress !==
                    FORM_PROGRESS.initiateQuestion ? (
                      <button
                        id='local-schedule-btn'
                        type='submit'
                        className='milan-btn milan-red-btn w-100'
                        disabled={!formik.isValid ? true : false}
                        onClick={() =>
                          dispatch({
                            type: 'formAction',
                            formAction: FORM_ACTIONS.selfSchedule,
                            formProgress: '',
                            user: formik.values,
                          })
                        }
                      >
                        See Available Times
                      </button>
                    ) : (
                      <span // Don't use button here
                        className='milan-btn milan-fallback-btn w-100'
                        onClick={() =>
                          dispatch({
                            type: 'formAction',
                            formAction: '',
                            formProgress: '',
                            user: formik.values,
                          })
                        }
                      >
                        Go Back
                      </span>
                    )}
                  </div>
                </div>
                <ConsultCheckbox />
              </Form>
            );
          }
        }
      </Formik>
    </div>
  );
}

export default SingleStore;
