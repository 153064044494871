import React from 'react'
import { FormikControl } from '..'
import { hardTrim } from '../../../utils'

// For Consultation Forms Only
export function UserInputs({ isQuestion, formik }) {
  return (
    <div className="row form-steps-container position-relative overflow-hidden">
      <div className="col form-inputs-wrapper">
        <div className="row justify-content-center">
          <div className="col-md-6 col-xl-5 mt-4 pt-md-1">
            <FormikControl control="input" name="first_name" placeholder="First Name*" type="text" />
          </div>
          <div className="col-md-6 col-xl-5 mt-4 pt-md-1">
            <FormikControl control="input" name="last_name" placeholder="Last Name*" type="text" />
          </div>
        </div>
        <div id="scrollToMessage" className="row justify-content-center">
          <div className="col-md-6 col-xl-5 mt-4 pt-md-1">
            <FormikControl control="phoneInput" name="phoneMask" placeholder="Phone Number*" type="tel" />
            <input type="hidden" name="phone" value={`+${hardTrim(formik.values.phoneMask)}`} />
          </div>
          <div className="col-md-6 col-xl-5 mt-4 pt-md-1">
            <FormikControl control="emailInput" name="email" placeholder="Your Email*" />
          </div>
        </div>
        <div className="row justify-content-center overflow-hidden my-4 pt-md-1">
          <div id="msg_area" className={`col-xl-10 mb-2 ${isQuestion ? 'slide-down' : ''}`}>
            <FormikControl control="textarea" name="description" placeholder="Message..." rows="3" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInputs