// $$$$$ IMPORTANT $$$$$$$ //
// Be carefull when updating the keys
export const questions = [
  {
    id: "age",
    question: "How old are you?",
    choices: [
      { key: 1, value: "15 Or Younger" },
      { key: 2, value: "16-18" },
      { key: 3, value: "19-29" },
      { key: 4, value: '30-39' },
      { key: 5, value: '40-49' },
      { key: 6, value: '50-59' },
      { key: 7, value: 'Over 60' },
    ]
  },
  {
    id: "hair_color",
    question: "What color is the hair you want to remove?",
    choices: [
      { key: 1, value: 'Red' },
      { key: 2, value: 'Blonde' },
      { key: 3, value: 'Gray' },
      { key: 4, value: 'White' },
      { key: 5, value: 'Brown' },
      { key: 6, value: 'Black' },
    ]
  },
  {
    id: "skin_tone",
    question: "What is your natural skin tone?",
    choices: [
      { key: 1, value: 'Pale White' },
      { key: 2, value: 'White' },
      { key: 3, value: 'Light Brown' },
      { key: 4, value: 'Olive' },
      { key: 5, value: 'Dark Brown' },
      { key: 6, value: 'Deeply Pigmented, Dark Brown to Black' },
    ]
  },
  {
    id: "tanning",
    question: "Do you regularly use tanning beds and/or sunbathe?",
    choices: [
      { key: 1, value: 'Yes' },
      { key: 2, value: 'No' },
    ]
  },
  {
    id: "pregnant",
    question: "Are you currently pregnant or plan to become pregnant soon?",
    choices: [
      { key: 1, value: 'Yes' },
      { key: 2, value: 'No' },
    ]
  },
]

export const quizResults = {
  too_young: "Laser hair removal may be an ideal choice for you in the future. However, you currently don’t meet the age requirement to go hair-free. We’re happy to treat clients at least 16 years of age with parental consent.",
  between_16_18: "Sounds like you’re an ideal candidate, but to enjoy all the benefits of laser hair removal, you will need parental consent. If that is the case, have a parent set up your free consultation today!",
  guardian: "*Milan clients in Nebraska require a parent or guardian between the ages of 16 and 18; clients in all other states require a parent or guardian for ages 16 and 17",
  over_60: "You might be a great candidate for laser hair removal, but we need more information. The color of your unwanted hair is an important factor. Contact an expert, book a consult, or call us to find out if you’re a good candidate for laser hair removal.",
  pregnant: "You may be a good candidate for laser hair removal in the future, but you will need to wait until you are no longer pregnant to begin your hair-free journey. In the meantime, set up a consultation with one of our laser hair removal professionals to find out more about laser hair removal and our fantastic promotional opportunities.",
  prime_tone: "Based on your results, you’re a fantastic candidate for laser hair removal! Book your free consultation now and enjoy your hair-free journey!",
  eligible_tone: "You’re eligible! Advances in laser hair removal technology have made laser hair removal safe for all skin tones. Set up a free consultation with one of our laser hair removal experts to find out which type of laser can safely take you on your hair-free journey!",
  tanned: "You are eligible for laser hair removal if you can maintain your natural skin tone before treatment. If you tan regularly – either with tanning beds or regular sun exposure – laser hair removal cannot be safely performed. Your skin needs to be its natural color for our Provider to select the proper settings with the laser.",
  rbg_hair: "You may be a candidate who can enjoy the benefits of laser hair removal, but we need more information to determine if you can be safely treated. Red, blonde, and gray hairs are frequently treated as long as the hair has some pigment. Set up a consultation with our laser hair removal experts to learn more.",
  white_hair: "Unfortunately, you are not a good candidate for laser hair removal. The laser targets pigment in the hair follicle during treatments. Hair that is pure white or peach fuzz will not respond to laser hair removal treatments.",
}

export const submitProgress = [
  {
    label: 'Calculating Results...',
    progress: 4
  },
  {
    label: 'Calculating Results...',
    progress: 22
  },
  {
    label: 'Avoiding Sun Exposure',
    progress: 44
  },
  {
    label: 'Applying Sunscreen',
    progress: 62
  },
  {
    label: 'Throwing Away Razors',
    progress: 92
  },
  {
    label: 'Drum Roll Please',
    progress: 100
  },
]