import React from 'react'
import { Field } from 'formik'
import { CORPORATE_SITE } from '../../../utils'

export function ConsentCheckbox(props) {
  const { label, name, mailchimpID, ...rest } = props
  return (
    <Field name={name}>
      {
        props => {
          const { field } = props
          return (
            <label htmlFor={mailchimpID}>
              By checking this box, you agree to Milan Laser Hair Removal to reach you by phone or text with information about our services, Msg & Data Rates May Apply. Reply Stop to cancel. View our <a href={CORPORATE_SITE+`/privacy.html`} target="_blank" rel="noreferrer" className="milan-text-navy text-decoration-underline">Privacy Policy</a>.
              <input
                type="checkbox"
                {...field} {...rest}
                value={field.value}
                id={mailchimpID}
                checked={field.value}/>
              <span className="checkmark"></span>
            </label>
          )
        }
      }
    </Field>
  )
}


export default ConsentCheckbox