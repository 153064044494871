import React, { useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'

export function CheckboxBtns(props) {
  const { name, options, formik, ...rest } = props
  const calcRef = rest.calcRef

  // Because we're using a custom checkbox, the touched prop is NOT being set to true automatically // Idk why
  useEffect(() => {
    if (formik.values.body_areas.length === 1 && !formik.touched.body_areas) {
      formik.setTouched({ ...formik.touched, 'body_areas': true })
      calcRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [formik.values.body_areas.length, formik.touched.body_areas])

  return (
    <>
      <Field name={name} {...rest} >
        {
          ({ field }) => (
            Object.keys(options).map((option, x) => (
              <li className="col-6 col-md-3 text-center custom-checkbox" key={x}>
                <label htmlFor={option} className={`${formik.values.body_areas.includes(option) ? 'checked' : ''}`}>{option}
                  <input
                    type="checkbox"
                    {...field}
                    value={option}
                    id={option} />
                </label>
              </li>
            ))
          )
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}


export default CheckboxBtns