import React from 'react'
import { YELP_URL_START, FACEBOOK_URL_START } from '../../../utils'
import { ReviewsBox, ReviewUsBtn, TempReviews } from '.'
import FACEBOOK_BTN from '../../../images/locations/reviews-facebook.png'
import GOOGLE_BTN from '../../../images/locations/reviews-google.png'
import YELP_BTN from '../../../images/locations/reviews-yelp.png'

export function ReviewsHero({currentMetro}) {
  const stores_with_reviews = currentMetro.stores.filter(store => store.platforms.reviews.list_token !== '')
  return (
    <section id="reviews-section" className="full-section background hero light-blue-bg">
      <div className="container">
        <div className="row justify-content-center text-center px-3">
          <div className="col-md-10">
            <h1 className="milan-text-primary">{currentMetro.name} Laser Hair Removal Reviews</h1>
            <p className="mt-3">Our clients love their permanent results from their laser hair removal at Milan. Even clients who received unsuccessful laser hair removal treatments somewhere else see amazing, permanent results with our lasers. <strong>Skeptical? Don’t just take our word for it</strong> – read laser hair removal reviews from real clients.</p>
          </div>
        </div>
        
        <div className="row mb-4 justify-content-around">
          <ReviewUsBtn currentMetro={currentMetro} link="" platform="google" btn={GOOGLE_BTN} />
          <ReviewUsBtn currentMetro={currentMetro} link={YELP_URL_START} platform="yelp" btn={YELP_BTN} />
          <ReviewUsBtn currentMetro={currentMetro} link={FACEBOOK_URL_START} platform="facebook" btn={FACEBOOK_BTN} />
        </div>
      </div>
      {
        stores_with_reviews.length === 0 ?
        <TempReviews /> : <ReviewsBox stores={stores_with_reviews} />
      }      
    </section>
  )
}

export default ReviewsHero