import React from 'react';

export function BikiniHero() {
  return (
    <section className='full-section background hero'>
      <div className='container'>
        <div className='bg-transparent-3 bg-sm-transparent p-2 rounded'>
          <h1 className='text-center pb-0' style={{ lineHeight: '2.5rem' }}>
            <span className='milan-text-primary d-block'>Bikini Line</span>
            <span style={{ fontSize: '2.5rem', lineHeight: '2.5rem' }}>
              Laser Hair Removal
            </span>
          </h1>
        </div>
        <div className='row my-4'>
          <div className='col-md-6 p-4'>&nbsp;</div>
          <div className='col-md-6 my-5'>
            <div className='bg-transparent-5 bg-xl-transparent p-3 p-xl-0 rounded'>
              <p>
                Removing hair from delicate areas such as your bikini line can
                be tricky.
              </p>
              <div className='d-none d-sm-block'>
                <p>Laser hair removal&#8230;</p>
                <ul className='list-style-disc'>
                  <li>
                    is <strong>less expensive</strong> than a monthly waxing
                    payment.
                  </li>
                  <li>
                    will target all of the hair, and is
                    <strong> less painful</strong> than waxing.
                  </li>
                  <li>
                    will, unlike shaving or IPLs, remove the unwanted hair{' '}
                    <strong>permanently</strong>.
                  </li>
                </ul>
              </div>
              <p className='mb-0'>
                Say hello to your smoothest bikini line ever.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default BikiniHero;
