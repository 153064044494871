import React from 'react'
import { Link } from "gatsby"

export function ForMenHero() {
  return (
    <section className="hero pt-milan-50 pb-0 py-md-milan-50 py-lg-milan-150 milan-bg-white">
      <div className="container">
        <div className="row">
          {/* <div className="offset-md-5 col-md-7 offset-xl-6 col-xl-6"> */}
          <div className="offset-md-6 col-md-6 offset-lg-5 col-lg-7 offset-xl-5 col-xl-7 offset-xxl-6 col-xxl-6">
            <h1 className="milan-text-primary">Laser Hair Removal For Men</h1>
            <p>Laser hair removal is the easy way to remove unwanted hair forever. Unwanted body hair like on the back or chest, can be difficult, stressful and embarrassing to maintain. Milan Laser offers a lasting solution for men, so you don’t have to deal with at home-kits, cheap devices, waxing or shaving ever again.</p>
            <p>You’ve found your way to smooth skin, permanently.</p>
            <div className="row">
            <div className="col-12 col-lg-auto text-center text-lg-start mb-2 mb-lg-0">
                <Link className="milan-btn milan-primary-btn milan-btn-width" to='/process'>The Process</Link>
              </div>
              <div className="col-12 col-lg-auto text-center text-lg-start mb-2 mb-lg-0">
                <Link className="milan-btn milan-primary-btn milan-btn-width" to='/specials'>Current Specials</Link>
              </div>
              <div className="col-12 col-lg-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn milan-btn-width" to='/process/beforeafter'>Before/After Photos</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-none stack-hero-bg" />
    </section>
  )
}

export default ForMenHero


    // <section className="full-section background hero">
    //   <div className="container">
    //     <div className="row my-lg-4 pt-3">
    //       <div className="col-md-4 col-lg-6 p-4 d-none d-md-block">&nbsp;</div>
    //       <div className="col-md-8 col-lg-6">
    //       <h1 className=" text-center text-md-start">Laser Hair Removal For Men</h1>
    //         <p>Laser hair removal is the easy way to remove unwanted hair forever. Unwanted body hair like on the back or chest, can be difficult, stressful and embarrassing to maintain. Milan Laser offers a lasting solution for men, so you don’t have to deal with at home-kits, cheap devices, waxing or shaving ever again.</p>
    //         <p>You’ve found your way to smooth skin, permanently.</p>
    //       </div>
    //     </div>
    //     <div className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-lg-5">
    //     <div><Link className="milan-btn milan-navy-btn mb-3" to="/process/">The Process</Link></div>
    //       <div><Link className="milan-btn milan-navy-btn mb-3" to="/specials/">Current Specials</Link></div>
    //       <div><Link className="milan-btn milan-navy-btn mb-3" to="/process/beforeafter/">Before/After Photos</Link></div>
    //     </div>
    //   </div>
    // </section>