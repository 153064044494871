import React from 'react'
import TempFrontDesk from '../../../images/locations/Temp_FrontDesk.webp'
import TempConsultationRoom from '../../../images/locations/Temp_ConsultationRoom.webp'
import TempTXRoom from '../../../images/locations/Temp_TXRoom.webp'
import TempLobby from '../../../images/locations/Temp_Lobby.webp'

const ImageWrapper = ({ img }) => {
  return (
    <div className="col-sm-6 col-lg-3">
      <img src={img} alt="Milan Clinic Interior Image" className="w-100 h-100" />
    </div>
  )
}

export function InteriorPhotos({ storeImages }) {
  return (
    <div className="row g-1 pb-1 justify-content-center navy-bg">
      <ImageWrapper img={storeImages.frontdesk || TempFrontDesk} />
      <ImageWrapper img={storeImages.consultationroom || TempConsultationRoom} />
      <ImageWrapper img={storeImages.lobby || TempLobby} />
      <ImageWrapper img={storeImages.txroom || TempTXRoom} />
    </div>
  )
}

export default InteriorPhotos