import React from 'react'
import { FormikControl } from '..'

export function ConsultCheckbox() {
  return (
    <div className="row justify-content-center my-3">
      <div className="col-xl-10 d-flex custom-checkbox">
        <FormikControl control="consentCheckbox" name="updates" mailchimpID="mailchimp-consult" />
      </div>
    </div>
  )
}

export default ConsultCheckbox