import React from 'react'
import { MILAN_SITE_START } from '../../../utils'

export function ShowAddressOrLink({formState, currentMetro}) {
  return (
    <>
    {
      formState.metro.name === currentMetro.name ? // This is true only when a nearby location is selected
      <>
      <strong>Selected location address:</strong>&nbsp;<br className="d-lg-none" />
      <span className="d-block d-md-inline">
        {formState.store.address}, <br className="d-sm-none" />
        {formState.store.addressCity === '' ? formState.store.marketingCity : formState.store.addressCity},&nbsp;
        {formState.store.stateAbbrev}&nbsp;{formState.store.zipCode}
      </span>
      </>
      : // This is true for all locations except the nearby ones
      <a href={`${MILAN_SITE_START}${formState.metro.pathname}.com/`} target="_blank" className="milan-text-navy p-link">
        Learn more about Milan Laser {formState.metro.name}
      </a>
    }
    </>
  )
}

export default ShowAddressOrLink
