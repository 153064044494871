import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'

export function AreasCheckboxes(props) {
  const { name, options, formik, ...rest } = props
  return (
    <>
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            return options.map(option => {
              return (
                <li className="col-6 col-lg-3 custom-checkbox" key={option}>
                  <label htmlFor={option} className="area_label">{option}
                    <input
                      type="checkbox"
                      {...field}
                      value={option}
                      id={option}
                      disabled={formik.values.bodyAreas.length >= 3 && !formik.values.bodyAreas.includes(option)} />
                    <span className="checkmark"></span>
                  </label>
                </li>
              )
            })
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}


export default AreasCheckboxes