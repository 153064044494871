import React from 'react';
import { Link } from 'gatsby';
import Dropdown from 'react-bootstrap/Dropdown';
import { ToWords } from 'to-words';
import { MILAN_PHONE, CORPORATE_SITE, trimAll, NO_RAZOR } from '../../utils';
import MAP_WITH_BADGE from '../../images/shared/map_with_badge.webp';

const toWords = new ToWords();

export function OurMetroDescription({ siteData }) {
  const openStoresInThisSite = siteData.currentMetro.stores.filter(
    (store) => store.open
  ); // returns Array
  const notOpenStoresInThisSite = siteData.currentMetro.stores.filter(
    (store) => !store.open
  ); // returns Array

  return (
    <section>
      <div id='generic-milan-hero' className='container-fluid background'></div>
      <div className='container pt-4 pb-5'>
        <div className='row'>
          <div className='col offset-lg-1 offset-xl-2'>
            <div className='row flex-lg-row-reverse'>
              <div className='col-lg-6'>
                <h2 className='subheadlg milan-text-primary d-lg-none'>
                  Largest Laser Hair Removal Company in the Country
                </h2>
                <div className='text-center mb-4 mt-md-4 mt-lg-5'>
                  <img
                    className='mw-100'
                    src={MAP_WITH_BADGE}
                    alt='Milan Laser Locations Map'
                  />
                </div>
              </div>
              <div className='col-lg-6 z-index-100'>
                <h2 className='subheadlg milan-text-primary'>
                  Our {siteData.currentMetro.name} Location
                  {siteData.currentMetro.stores.length > 1 ? 's' : ''}
                </h2>
                <p>
                  We are the largest laser hair removal company in the country
                  with more than
                  <Link className='milan-text-primary' to='/locations/'>
                    {' '}
                    {siteData.milanOpenStores.countToDisplay} locations
                  </Link>
                  {siteData.currentState &&
                  siteData.currentState.openStores > 1 ? (
                    <span>
                      —
                      <a
                        className='milan-text-primary'
                        href={`${CORPORATE_SITE}/locations/${trimAll(
                          siteData.currentMetro.state
                        ).toLowerCase()}/`}
                        target='_blank'
                      >
                        {siteData.currentState.openStores} of which are in{' '}
                        {siteData.currentMetro.state}
                      </a>
                      —
                    </span>
                  ) : (
                    ' '
                  )}
                  in {siteData.milanStores.length} states, and we are the only
                  company that includes the{' '}
                  <Link
                    className='milan-text-primary'
                    to='/specials/#guarantee'
                  >
                    Unlimited Package™
                  </Link>{' '}
                  with every purchase.
                </p>
                {/* For single location site && open */}
                {siteData.currentMetro.stores.length === 1 &&
                  openStoresInThisSite.length === 1 &&
                  openStoresInThisSite[0].description.line_one !== '' && (
                    <p>
                      Our {siteData.currentMetro.name} location is conveniently
                      located {openStoresInThisSite[0].description.line_one}
                      {openStoresInThisSite[0].description.line_two}.
                    </p>
                  )}
                {/* For multiple locations site */}
                {siteData.currentMetro.stores.length > 1 &&
                  openStoresInThisSite.length !== 0 && ( // at least one location is open
                    <p
                      className={`mb-2 ${
                        siteData.currentMetro.stores.length <= 4
                          ? 'd-inline'
                          : ''
                      }`}
                    >
                      We have{' '}
                      {toWords
                        .convert(openStoresInThisSite.length)
                        .toLowerCase()}{' '}
                      convenient {siteData.currentMetro.name} area locations
                      {notOpenStoresInThisSite.length === 0 ? (
                        '.'
                      ) : (
                        <span>
                          , with{' '}
                          {toWords
                            .convert(notOpenStoresInThisSite.length)
                            .toLowerCase()}{' '}
                          more location
                          {notOpenStoresInThisSite.length === 1 ? '' : 's'}{' '}
                          opening soon.
                        </span>
                      )}
                    </p>
                  )}
                {
                  // For sites with more than 4 locations -> List Style
                  siteData.currentMetro.stores.length > 4 ? (
                    <ul className='list-style-disc ps-4 mb-2 text-start'>
                      {openStoresInThisSite.length !== 0 &&
                        openStoresInThisSite.map((store, x) => (
                          <li key={x}>
                            {store.marketingCity}-{' '}
                            {store.description.line_one !== ''
                              ? store.description.line_one
                                  .charAt(0)
                                  .toUpperCase() +
                                store.description.line_one.slice(1)
                              : store.address}
                          </li>
                        ))}
                      {notOpenStoresInThisSite.length !== 0 &&
                        notOpenStoresInThisSite.map((store, x) => (
                          <li key={x}>{store.marketingCity}- Coming soon</li>
                        ))}
                    </ul>
                  ) : (
                    // For sites with less than 4 locations -> Paragraph Style
                    <p className='d-inline'>
                      {
                        // at least one location is open
                        openStoresInThisSite.length !== 0 &&
                          siteData.currentMetro.stores.length !== 1 && (
                            <>
                              {' We are currently open '}
                              {openStoresInThisSite.map((store, x) => (
                                <span key={x}>
                                  {store.description.line_one !== ''
                                    ? store.description.line_one
                                    : `at ${store.address}`}{' '}
                                  in {store.marketingCity}
                                  {x === openStoresInThisSite.length - 2
                                    ? '; and '
                                    : x === openStoresInThisSite.length - 1
                                    ? '. '
                                    : '; '}
                                </span>
                              ))}
                            </>
                          )
                      }
                      {
                        // at least one location is NOT open
                        notOpenStoresInThisSite.length !== 0 && (
                          <>
                            {`Our new location${
                              notOpenStoresInThisSite.length > 1
                                ? 's are'
                                : ' is'
                            } coming soon to `}
                            {notOpenStoresInThisSite.map((store, x) => (
                              <span key={x}>
                                {store.marketingCity}
                                {x === notOpenStoresInThisSite.length - 2
                                  ? ', and '
                                  : x === notOpenStoresInThisSite.length - 1
                                  ? '. '
                                  : ', '}
                              </span>
                            ))}
                          </>
                        )
                      }
                    </p>
                  )
                }
                <p className='pb-4'>
                  {'For more information, call '}
                  {siteData.currentMetro.stores.length === 1 ? (
                    <a
                      href={`tel:${siteData.currentMetro.stores[0].phone}`}
                      className='milan-text-primary text-nowrap'
                    >
                      {siteData.currentMetro.stores[0].phone}
                    </a>
                  ) : siteData.currentMetro.stores.length === 2 ? (
                    <>
                      <a
                        href={`tel:${siteData.currentMetro.stores[0].phone}`}
                        className='milan-text-primary text-nowrap'
                      >
                        {siteData.currentMetro.stores[0].phone}
                      </a>
                      {', '}
                      <a
                        href={`tel:${siteData.currentMetro.stores[1].phone}`}
                        className='milan-text-primary text-nowrap'
                      >
                        {siteData.currentMetro.stores[1].phone}
                      </a>
                    </>
                  ) : (
                    <a
                      href={`tel:${MILAN_PHONE}`}
                      className='milan-text-primary text-nowrap'
                    >
                      {NO_RAZOR}
                    </a>
                  )}
                  {' or'}{' '}
                  <Link className='milan-text-primary' to='/locations/contact/'>
                    submit your question online
                  </Link>
                  .
                </p>
                {siteData.currentMetro.stores.length === 1 ? ( // Single location -> Button
                  <div className='text-center text-md-start mb-4'>
                    <Link
                      to={`/locations/${siteData.currentMetro.stores[0].pathname}/`}
                      className='milan-btn milan-cta-btn w-auto'
                    >
                      Learn More About Our {siteData.currentMetro.name} Location
                    </Link>
                  </div>
                ) : (
                  // Multiple locations -> Dropdown
                  <div className='text-center text-md-start'>
                    <Dropdown className='text-center text-md-start mb-4 d-inline-block'>
                      <Dropdown.Toggle
                        className='milan-btn milan-cta-btn w-100'
                        style={{ whiteSpace: 'normal' }}
                      >
                        Learn More About Our {siteData.currentMetro.name}{' '}
                        Locations
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='py-0 w-100'>
                        {siteData.currentMetro.stores.map((store, x) => (
                          <Link
                            to={`/locations/${siteData.currentMetro.metroPath}/${store.pathname}/`}
                            className='border-bottom d-block w-100 text-center py-1 hover-navy dropdown-item'
                            key={x}
                          >
                            {store.marketingCity}
                          </Link>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default OurMetroDescription;
