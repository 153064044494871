import React from 'react'

export function FaqsHero() {
  return (
    <section className="hero faqs-hero">
      <div className="container px-3">
        <div className="row">
          <div className="col offset-xl-2 mr-5 mr-sm-0 pr-5 pr-sm-0">
            <h1 className="my-3 my-sm-5 milan-text-primary">FAQs About <br />Laser Hair Removal</h1>
            <h2 className="subheadsm pt-3 pt-sm-0 pe-5 pe-sm-0">Get answers about cost &amp; <br /> effectiveness of laser hair removal.</h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqsHero