import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

export function UnlimitedPackage({ siteData }) {
  return (
    <section id="unlimited-package" className="background unlimited-package-bg">
      <span id="guarantee"></span>
      <div className="container milan-py">
        <div className="row">
          <div className="col offset-lg-1 offset-xl-2">
            <div className="row">
              <div className="col">
                <h2 className="subheadlg milan-text-primary">The Unlimited Package&#8482;</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-lg-6 col-xxl-5">
                <p>Exclusively at Milan Laser Hair Removal, <strong>the Unlimited Package™</strong> comes with every purchase and includes <strong>all the treatments you need for life</strong>. New hair growth? We’ll take care of it anytime at <strong>no extra charge</strong>. Moving or traveling? Get treated at any of our <strong>{siteData?.milanOpenStores.countToDisplay}+ clinics</strong> nationwide!</p>
                <div className="d-md-none text-center my-5">
                  <StaticImage src="../../../images/shared/Unlimited-Package.png" style={{ maxWidth: '200px' }} alt="Exclusively at Milan Laser The Unlimited Package Lifetime Guarantee No Surprise Costs" />
                </div>
                <p className="p-subhead mb-3 milan-text-primary">Why Unlimited Sessions?</p>
                <p>Your body will change. For men, you may see increased body hair growth through adulthood. For women, hormone balances change, and some (changes in activity levels, PMS, pregnancy, menopause, etc.) can stimulate your hair growth.</p>
                <p>At Milan, you’ll never have to worry about paying for additional sessions because of life’s events though. We include the Unlimited Package with every body area, so <strong>your results are guaranteed for life!</strong></p>
                <p className="mb-0"><Link to="/process/quiz/" className="milan-text-primary">Am I a good candidate for laser hair removal?</Link></p>
              </div>
              <div className="col-md-4 col-lg-3 d-none d-md-block">
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <StaticImage src="../../../images/shared/Unlimited-Package.png" style={{ maxWidth: '200px' }} alt="Exclusively at Milan Laser The Unlimited Package Lifetime Guarantee No Surprise Costs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background spacer d-lg-none"></div>
    </section>
  );
}
export default UnlimitedPackage;
