import qs from 'qs';
import axios from 'axios';
import { FAQS_FORM_HANDLER, hardTrim } from '../../../../utils';
import { navigate } from 'gatsby';

export const submitFaqsForm = (user, props, currentMetro) => {
  axios({
    method: 'POST',
    url: FAQS_FORM_HANDLER,
    data: qs.stringify({
      first_name: user.first_name,
      last_name: user.last_name,
      description: user.description,
      phone: `+${hardTrim(user.phone)}`,
      email: user.email,
      updates: user.updates,
      '00N1L00000F9eBV': user.location,
      oid: process.env.GATSBY_OID,
      lead_source: 'FAQ Submission',
      retUrl: currentMetro.origin + '/process/faqs/',
      Campaign_ID: '7011L000001hWzN',
      cloudamp__data__c: localStorage.getItem('cloudamp_value'),
    }),
    config: {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  })
    .then((res) => {
      console.log(res.status, 'Faqs form submitted');
      props.setSubmitting(false);
      props.setStatus('Success');
      navigate('?faqs-form-success#faqs-form');
    })
    .catch((err) => {
      console.error(err);
      props.setSubmitting(false);
      props.setStatus('Failed');
    });
};
