import React, { useState, useEffect } from 'react'
import { submitProgress } from './quizConstants';
import ProgressBar from 'react-bootstrap/ProgressBar';

const SubmitProgressBar = ({ duration }) => {
  const steps = submitProgress.length - 1
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < steps ? prevProgress + 1 : prevProgress));
    }, duration / steps);

    return () => clearInterval(interval);
  }, [steps, duration]);

  return (
    <div id="submit-progress-bar" className="progress-bar-wrapper">
      <div id="submit-progress-bar-wrapper">
        <p><strong>{submitProgress[progress].label}</strong></p>
        <ProgressBar now={submitProgress[progress].progress} />
      </div>
    </div>
  )
};

export default SubmitProgressBar