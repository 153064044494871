import React from "react";

export function MenBackAndShoulder() {
  return (
    <section id="men-back-shoulder" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 milan-bg-tur milan-text-white py-milan-50 py-lg-milan-150">
            <div className="row">
              <div className="col-auto">
                <h2 className="subheadlg">Back & Shoulder Laser Hair Removal</h2>
              </div>
              <div className="col-12 z-index-100 order-2 order-md-1">
                <p>
                  Shaving your back is complicated, and waxing is painful. Laser hair removal will eliminate the hair at the root so you don’t have the aftermath of ingrown hairs
                  or patchy spots, just continuously soft skin for the rest of your life. Say goodbye to manscaping contraptions and cheap devices, and say hello to your hair-free
                  future.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 vrt-divider vrt-divider-reversed vrt-divider-turq" />
        </div>
      </div>
      <div className="spacer d-lg-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    </section>
  );
}
export default MenBackAndShoulder;

// <section id="men-back-shoulder" className="full-section background">
//   <div className="container">
//     <div className="row mt-lg-4 py-3">
//       <div className="col-md-6"></div>
//       <div className="col-md-6">
//       <h2 className="subheadlg">Back &amp; Shoulder Laser Hair Removal</h2>
//         <p>Shaving your back is complicated, and waxing is painful. Laser hair removal will eliminate the hair at the root so you don’t have the aftermath of ingrown hairs or patchy spots, just continuously soft skin for the rest of your life. Say goodbye to manscaping contraptions and cheap devices, and say hello to your hair-free future.</p>
//       </div>
//     </div>
//   </div>
// </section>
