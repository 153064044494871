import React from 'react'

export function SignUpConsentMsg() {
  return (
    <>
      I consent by electronic signature to be contacted by Milan Laser by email for offers or advertisements via email at the email address provided.
    </>
  )
}

export default SignUpConsentMsg