import React from 'react';
import { Formik, Form } from 'formik';
import {
  FormikControl,
  signUpInitialValues as initialValues,
  signUpSchema as validationSchema,
  submitSignUpForm as onSubmit,
  FormSubmitting,
  SignUpSuccess,
  FormFailed,
  SubmitBtn,
} from '..';

export function SignUpForm({ siteData, isFooter }) {
  initialValues.location = siteData.currentMetro.stores[0].salesforceValue;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(user, props) =>
        onSubmit(user, props, siteData.currentMetro, isFooter)
      }
      validateOnChange={false}
    >
      {(formik) => {
        return (
          <Form className='w-100'>
            {formik.isSubmitting ? (
              <FormSubmitting />
            ) : formik.status === 'Success' ? (
              <SignUpSuccess />
            ) : formik.status === 'Failed' ? (
              <FormFailed />
            ) : (
              <>
                {/* <div className='row'> */}
                <div className='row mb-3 text-start gx-3'>
                  <div className='col-12 col-sm-6 mb-3 mb-sm-0'>
                    <FormikControl
                      control='input'
                      name='firstName'
                      placeholder='First Name*'
                      type='text'
                      required
                      role='input'
                    />
                  </div>
                  <div className='col-12 col-sm-6'>
                    <FormikControl
                      control='input'
                      name='lastName'
                      placeholder='Last Name*'
                      type='text'
                      required
                      role='input'
                    />
                  </div>
                </div>
                <div className='mb-3 text-start'>
                  <FormikControl
                    control='emailInput'
                    name='email'
                    placeholder='Your Email*'
                    required
                    role='input'
                  />
                </div>
                <div className='text-start'>
                  <FormikControl
                    control='phoneInput'
                    name='phoneMask'
                    placeholder='Phone Number*'
                    type='tel'
                    required
                    role='input'
                  />
                </div>
                {siteData.currentMetro.stores.length > 1 && (
                  <div className='mt-3'>
                    <FormikControl
                      control='selectLocation'
                      name='location'
                      label='Location'
                      milanStores={siteData.milanStores}
                    />
                  </div>
                )}
                <div className='row mt-1'>
                  <div className='col-12 mb-3'>
                    <p className='text-center mt-2 mb-0 lh-1'>
                      <small className='lh-1'>
                        I consent by electronic signature to be contacted by
                        Milan Laser by live agent, email & automatic telephone
                        dialer for information, offers or advertisements via
                        email/ phone call/ text message at the number & email
                        provided. No purchase necessary.
                      </small>
                    </p>
                  </div>
                  <div className='col-12 text-center'>
                    <SubmitBtn formik={formik} />
                  </div>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SignUpForm;
