import React from "react"
import { Link } from "gatsby"

export function DarkerSkin() {
  return (
    <section id="areas-darker" className="py-milan-50 py-md-milan-100 py-lg-milan-150">
      <div className="container">
        <div className="row">
          <div className="col offset-lg-6 col-lg-6">
            <div className="row">
              <div className="col">
                <h2 className="subheadlg milan-text-primary">Laser Hair Removal for Darker Skin Tones</h2>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col col col-xl-10">
                <p> Our laser hair removal works on all skin tones. Some hair removal methods are limited to light skin but our laser is precise enough to target the hair follicle and eliminate hair growth at the root without damaging the surface of the skin. Even the darkest of skin tones can be treated.</p>
                <p className="mb-0"><Link to="/areas/darkskin/" className="milan-text-primary">Get the facts about laser hair removal for dark skin tones.</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stack-hero-bg d-md-none"></div>
    </section>
  )
}
export default DarkerSkin