import React from "react";
import Layout from "./src/templates/Layout";
import { GlobalContextProvider } from "./src/context";
// import SSRProvider from "react-bootstrap/SSRProvider";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement( { element }) {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

// const React = require("react")
// const {GlobalContextProvider} = require('./src/context/GlobalContextProvider') 

// exports.wrapRootElement = ({ element }) => {
//   return (
//     <GlobalContextProvider>{element}</GlobalContextProvider>
//   )
// }
