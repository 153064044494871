import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useReviewCount } from "../../../hooks";

export function FiveStarsDivider({ currentMetro }) {
  const googelReviews = useReviewCount(currentMetro)
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <div className="container">
        <div className="row flex-column align-items-center milan-text-white text-center">
          <div className="col z-index-100">
            <Link className="d-inline-block mb-3" to="/locations/reviews/">
              <StaticImage src="../../../images/shared/Divider-5star.png" style={{ maxWidth: "350px" }} alt="Milan Laser Rate" />
            </Link>
            {
              googelReviews.avgRating !== '0' && googelReviews.reviewCount !== '0' &&
              <p className="p-subhead">Rated {googelReviews.avgRating}/5 based on {googelReviews.reviewCount} reviews</p>
            }
            <p className="p-subhead"><Link to="/locations/reviews/" className="milan-text-secondary">See Our Reviews</Link></p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FiveStarsDivider