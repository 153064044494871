export function useReviewCount(currentMetro) {
  let count = 0
  let ratingSum = 0
  let reviewCount = 0

  currentMetro.stores.forEach(store => {
    if(store.rating && store.reviewCount) {
      count += 1
      ratingSum += store.rating
      reviewCount += store.reviewCount
    }
  })
  return {
    avgRating: (ratingSum/count).toString().substring(0,3),
    reviewCount: reviewCount.toString()
  }
}

export default useReviewCount