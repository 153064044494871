import React from "react";
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

export function BackWaxCostDivider() {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
        {({ isVisible }) => (
          <div className="container">

            {/* <div className="row justify-content-center milan-text-white text-center pt-1">
              <div className="col-10 col-xl-12">
                <p className="p-subhead mb-0">
                  A man getting a monthly back wax will spend over
                </p>
              </div>
              <div className="col-12">
                <h3 className="subpointlg"><strong><span className="milan-text-secondary width-fluid-1">${isVisible ? <CountUp end={18} duration={2} /> : 0},000</span></strong> in his lifetime.</h3>
              </div>
            </div> */}

            <div className="row justify-content-center milan-text-white text-center">
              <div className="h-100 col-12 col-md-12 d-xl-flex align-items-center justify-content-center">
                <p className="p-subhead mb-0 d-inline">
                  A man getting a monthly back <br className="d-block d-md-none"/>wax will spend over
                </p>
                {/* Inline show */}
                {/* <p className="p-subhead d-none d-xl-inline ps-3"> after <strong>7&#8209;10</strong> treatments On average.</p> */}
                <h3 className="subpointlg d-none d-xl-inline ps-3"><span className="width-fluid-1"><strong><span className="milan-text-secondary">${isVisible ? <CountUp end={18} duration={2} /> : 0},000</span></strong> in his lifetime.</span></h3>
                {/* Stacked */}
                {/* <p className="p-subhead d-block d-xl-none mb-0"> after <strong>7&#8209;10</strong> treatments <br className="d-block d-sm-none"/>On average.</p> */}
                <h3 className="subpointlg d-block d-xl-none mb -0"><span className="width-fluid-1"><strong><span className="milan-text-secondary">${isVisible ? <CountUp end={18} duration={2} /> : 0},000</span></strong> in his lifetime.</span></h3>
              </div>
            </div>

          </div>
        )}
      </VisibilitySensor>
    </section>
  )
}
export default BackWaxCostDivider