import React, { useState } from 'react'
import { quizResults } from './quizConstants'
import QuizResult from './QuizResult';
import SubmitProgressBar from './SubmitProgressBar';
import QuestionsProgressBar from './QuestionsProgressBar'
import QuizIntro from './QuizIntro';
import QuizBox from './QuizBox';

const duration = 6000

export function QuizHome() {
  const [start, setStart] = useState(false)
  const [questionIndex, setQuestionIndex] = useState(0)
  const [result, setResult] = useState(null)
  const [showSubmitProgress, setShowSubmitProgress] = useState(false)

  const startQuiz = () => {
    setStart(true)
    setQuestionIndex(0)
    setResult(null)
    setShowSubmitProgress(false)
  }

  return (
    <section id="quiz-hero" className="hero position-relative pt-0">
      <div className="d-md-none stacked-hero-bg"></div>
      <div className="container pt-milan-50 pt-md-0 position-relative">
        <div className="row">
          <div className="col-md-7 col-xl-6 offset-md-5">
            <h1 className="milan-text-primary text-capitalize">Are You A Good Candidate For Laser Hair Removal?</h1>
            {
              !start ?
                <QuizIntro startQuiz={startQuiz} />
                :
                result ?
                  <QuizResult {...{ startQuiz, result, quizResults }} />
                  :
                  <div className="quiz-wrapper">
                    {
                      showSubmitProgress ?
                        <SubmitProgressBar duration={duration} />
                        :
                        <div className="quiz-wrapper">
                          <QuestionsProgressBar questionIndex={questionIndex} />
                          <QuizBox {...{
                            questionIndex,
                            setQuestionIndex,
                            setShowSubmitProgress,
                            setStart,
                            setResult,
                            duration
                          }} />

                        </div>
                    }
                  </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuizHome
