import React from 'react'
import { questions, quizResults } from './quizConstants'
import { Formik, Form } from 'formik'
import QuizCarousel from './QuizCarousel';


const initialValues = {}
questions.forEach(q => initialValues[q.id] = null)

function QuizBox({ questionIndex, setQuestionIndex, setShowSubmitProgress, setStart, setResult, duration }) {

  const handlePrev = () => {
    if (questionIndex === 0) {
      setStart(false)
    } else {
      setQuestionIndex(prev => prev - 1);
    }
  };
  const handleNext = (e) => {
    e.preventDefault() // For some reason the Next button sometimes submits the form
    setQuestionIndex(prev => prev + 1);
  };

  const onSubmit = (values, helpers) => {
    setShowSubmitProgress(true)
    setTimeout(() => {
      const { age, hair_color, pregnant, skin_tone, tanning } = values
      if (age == 1) {
        setResult(quizResults.too_young)
      } else if (pregnant == 1) {
        setResult(quizResults.pregnant)
      } else if (age == 2) {
        setResult(quizResults.between_16_18)
      } else if (age == 7) {
        setResult(quizResults.over_60)
      } else if (hair_color == 4) {
        setResult(quizResults.white_hair)
      } else if (+hair_color <= 3) {
        setResult(quizResults.rbg_hair)
      } else if (tanning == 1) {
        setResult(quizResults.tanned)
      } else if (skin_tone == 1 || skin_tone == 2) {
        setResult(quizResults.prime_tone)
      } else if (+skin_tone > 2) {
        setResult(quizResults.eligible_tone)
      }
      setShowSubmitProgress(false)
      setTimeout(() => {
        helpers.resetForm()
      }, 3000)
    }, duration + 600)
  }

  return (
    <div className="quiz-box">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {
          ({ values }) => {
            return (
              <Form id="quiz-form">
                <QuizCarousel questionIndex={questionIndex} />
                <div className="text-center mt-3">
                  <button type='button' className="milan-btn-sm milan-grey-btn quiz-step-btn mx-2" onClick={handlePrev}>Go Back</button>
                  {
                    (questionIndex + 1) < questions.length ?
                      <button
                        type="button"
                        className="milan-btn-sm milan-primary-btn quiz-step-btn mx-2"
                        onClick={handleNext}
                        disabled={!values[questions[questionIndex].id]}
                      >Next Question</button>
                      :
                      <button
                        type="submit"
                        className="milan-btn-sm milan-red-btn mx-2"
                        disabled={!values[questions[questionIndex].id]}
                      >See Results</button>
                  }
                </div>

              </Form>
            )
          }
        }
      </Formik>
    </div>
  )
}

export default QuizBox