import React from 'react';
import { Link } from 'gatsby';

export function LHRSpecials() {
  return (
    <section id='lhr-specials' className='milan-bg-tur milan-credit-bg'>
      <div className='background spacer d-lg-none hr-divider hr-divider-reversed-flipped hr-divider-turq-light'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-xxl-6 vrt-divider vrt-divider-turq-alt'></div>
          <div className='col-lg-7 col-xxl-6 milan-bg-tur milan-text-white milan-py'>
            <div className='row'>
              <div className='col'>
                <h2 className='subheadlg'>Laser Hair Removal Specials</h2>
                <p>
                  Say goodbye to unwanted hair on your – underarms, bikini area,
                  lip/chin, legs and back – with one of our great deals!{' '}
                  <Link to='#consult'>Schedule a free consult</Link> today to
                  take advantage of current specials.
                </p>
                <p>
                  We believe you deserve to have hair-free skin at a price you
                  can afford. That’s why in addition to offering great specials,
                  we approve everyone for affordable payment plans.
                </p>
                <p>
                  <Link to='/specials/#financing'>
                    Learn more about the payment plan options.
                  </Link>
                </p>
              </div>
            </div>
            <div className='row gy-3 gx-lg-2 gx-xl-4 pt-5'>
              <div className='col-12 col-md-auto text-center'>
                <Link className='milan-btn milan-navy-btn' to='/process/'>
                  The Process
                </Link>
              </div>
              <div className='col-12 col-md-auto text-center'>
                <Link className='milan-btn milan-navy-btn' to='/process/faqs/'>
                  Laser Hair Removal FAQs
                </Link>
              </div>
              <div className='col-12 col-md-auto text-center'>
                <Link
                  className='milan-btn milan-navy-btn column-fallback-btn'
                  to='/locations/about-us/'
                >
                  About Milan Laser
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LHRSpecials;
