import { trimAll } from '.'
// Accepts the salesforce value as a param
// Returns the store object of the passed salesforce value (The store NOT the metro)
export const getStore = (salesforceValue, milanStores) => {
  const filteredState = milanStores.find(state => {
    // This returns the state of the requested store
    return state.metros.some(findLocation)
  })
  const filteredMetro = filteredState.metros.find(findLocation)

  function findLocation(metro) {
    return metro.stores.some(store => store.salesforceValue === salesforceValue)
  }
  let filteredStore = filteredMetro.stores.find(store => store.salesforceValue === salesforceValue)
  return {
    state: {
      name: filteredState.name,
      abbreviation: filteredState.abbreviation,
      pathname: trimAll(filteredState.name.toLowerCase())
    },
    metro: {
      name: filteredMetro.name,
      pathname: filteredMetro.pathname,
      metroPath: filteredMetro.metroPath,
      stateAbbrev: filteredMetro.stateAbbrev,
    },
    store: {...filteredStore}
  }
}

export default getStore