export const splitAreaNodes = arr => {
  let splittedArr = []
  // Basically split the array of nodes into sub arrays of nodes
  // Each sub array will have 2 nodes only
  for (let i = 1; i <= arr.length / 2; i++) {
    // the subArr will be [Area_i_After, Area_i_Before]
    let subArr = arr.filter(elem => elem.node.name.includes(i)).sort((a, b) => a.node.name.localeCompare(b.node.name))
    splittedArr.push(subArr)
  }
  return splittedArr
}