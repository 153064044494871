import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export function ProcessReviewTwo() {
  return (
    <section className="position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="quote-circle" src="../../../images/process/home/quote-circle.png" imgClassName="middle-icon" />
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center text-with-quotes">
            <figure className="text-center px-2 mb-0 limited-width">
              <blockquote>The staff at Milan are <strong>experts in laser treatment</strong>. They’re educated on the whole process,
                and you can ask them questions or share your concerns, no matter how silly ...</blockquote>
              <figcaption><strong>- Sifa Ahmad Hasan</strong></figcaption>
            </figure>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center milan-py-lg">
            <StaticImage className="reviewer-img" alt="Sifa Ahmad Hasan Review" src="../../../images/process/home/sifa.webp" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProcessReviewTwo