import React from 'react'
import { Field } from 'formik'
import { Slider, styled } from '@mui/material';

const AgeSlider = styled(Slider)({
  color: 'var(--milan-turquoise)',
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: 'var(--milan-off-white)',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: '1.2rem',
    background: 'unset',
    padding: 0,
    width: 44,
    height: 44,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: 'var(--milan-turquoise)',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const marks = [
  {
    value: 18,
    label: '18'
  },
  {
    value: 75,
    label: '75'
  },
];

export function RangeSliderInput(props) {
  const { name, formik } = props
  return (
    <Field
      component={AgeSlider}
      name={name}
      defaultValue={35}
      value={formik.values.age}
      aria-labelledby="discrete-slider-restrict"
      step={1}
      valueLabelDisplay="on"
      marks={marks}
      min={18}
      max={75}
      onChange={(e) => formik.setFieldValue('age', e.target.value)}
    />
  )
}

export default RangeSliderInput