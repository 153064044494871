import React from 'react'

export function AffordablePlansDivider() {
    return (
        <section className="divider-section w-100 py-4 milan-bg-navy d-block">
            <div className="container">
                <div className="row justify-content-center milan-text-white text-center py-1">
                    <div className="col-12">
                        <h3 className="subpointlg">
                            My{' '}
                            <strong className="milan-text-tur">results</strong>{' '}
                            are amazing!
                        </h3>
                        <figure className="p-subhead">
                            <blockquote className="mb-0 d-none d-md-block">
                                The staff is very professional, great location,
                                and
                            </blockquote>
                            <blockquote className="mb-0">
                                <span className="d-inline d-md-none">
                                    ...Affordable
                                </span>{' '}
                                payment plans make it easy.{' '}
                                <span className="d-inline-block">
                                    <strong>- Kimberly N.</strong>
                                </span>
                            </blockquote>
                        </figure>
                    </div>
                </div>
            </div>
            <span id="financing"></span>
        </section>
    )
}
export default AffordablePlansDivider
