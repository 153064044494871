import React from 'react';
import {QuoteForm} from '../../forms';
import {StaticImage} from 'gatsby-plugin-image';

export function CustomQuote({siteData}) {
  return (
    <section id='custom-quote' className='full-section background hero light-blue-bg mb-5'>
      <div className='container'>
        <div id='quote-header'>
          <h1 className='milan-text-primary text-center mb-3'>Get a Custom Quote</h1>
          <p className='text-center p-subhead'>Fill out the form below to request your quote.</p>
        </div>
        <div className='row justify-content-center'>
          <div className='quote-wrapper col-lg-10 mx-auto py-3 mt-3 bg-white shadow'>
            <div className='my-3 text-center'>
              <StaticImage src='../../../images/shared/Milan-Logo-Blue.webp' alt='Milan Laser Logo' className='w-100' style={{maxWidth: '180px'}} />
            </div>
            <QuoteForm siteData={siteData} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomQuote;
