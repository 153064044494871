import qs from 'qs';
import axios from 'axios';
import { NEWSLETTER_FORM_HANDLER, hardTrim } from '../../../utils';
import { navigate } from 'gatsby';

export const submitSignUpForm = (user, props, currentMetro, isFooter) => {
  props.setSubmitting(true);
  axios({
    method: 'POST',
    url: NEWSLETTER_FORM_HANDLER,
    data: qs.stringify({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      phone: `+${hardTrim(user.phoneMask)}`,
      updates: true,
      '00N1L00000F9eBV': user.location,
      oid: process.env.GATSBY_OID,
      lead_source: 'Newsletter',
      retUrl: currentMetro.origin,
      cloudamp__data__c: localStorage.getItem('cloudamp_value'),
    }),
    config: {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  })
    .then((res) => {
      console.log(res.status, 'Subscribed to mailchimp');
      props.setSubmitting(false);
      props.setStatus('Success');
      isFooter
        ? navigate('?signup-footer#footer-top')
        : navigate('?signup-success');
      setTimeout(() => {
        props.resetForm();
      }, 4000);
    })
    .catch((err) => {
      console.error(err);
      props.setSubmitting(false);
      props.setStatus('Failed');
    });
};
