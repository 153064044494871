import React from 'react'
import STEP_ONE_IMG from '../../images/lp/Step-1.webp'
import STEP_TWO_IMG from '../../images/lp/Step-2.webp'
import STEP_THREE_IMG from '../../images/lp/Step-3.webp'

export function HowLHRWorks() {
  return (
    <section id="lhr-steps" className="milan-bg-tur milan-py-lg position-relative">
      <div className="container milan-text-white">
        <h2 className="subheadlg text-center">How Laser Hair Removal Works</h2>
        <div className="row gy-4 mt-1">
          <div className="col-md-4 text-center">
            <figure>
              <img src={STEP_ONE_IMG} alt="Laser Hair Removal Process - Step One" />
              <figcaption className="mt-3">Lasers target hair at the root.</figcaption>
            </figure>
          </div>
          <div className="col-md-4 text-center">
            <figure>
              <img src={STEP_TWO_IMG} alt="Laser Hair Removal Process - Step Two" />
              <figcaption className="mt-3">The root is destroyed so hair <br /> never grows&nbsp;back.</figcaption>
            </figure>
          </div>
          <div className="col-md-4 text-center z-index-100">
            <figure>
              <img src={STEP_THREE_IMG} alt="Laser Hair Removal Process - Step Three" />
              <figcaption className="mt-3">Hair is gone forever.</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowLHRWorks