import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import TREATMENT_ICON from '../../../images/process/home/icon-three-six-treatments.webp'

export function ProcessTreatments() {
  return (
    <section id="treatments" className="background bg-center position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="arrow-right" src="../../../images/process/home/arrow-right.png" imgClassName="middle-icon" />
        <div className="row flex-sm-row-reverse">
          <div className="col-md-6">
            <div className="process-step milan-text-white text-center mx-auto px-sm-4 px-md-5">
            <h3 className="subpointlg">Treatments <strong>3-6</strong></h3>
              <p className="mb-0 pt-4">Welcome back! You’ll notice you don’t have to shave as often and hair is noticeably more sparse. After your first treatment, the laser power will begin to increase with each visit to provide you with the best results possible.</p>
            </div>
          </div>
          <div className="col-md-6 border-right">
            <figure className="text-center h-100 d-flex align-items-center justify-content-center pb-4 pb-md-0">
              <img alt="Milan Laser Hair Removal Process - Treatment Checklist" src={TREATMENT_ICON} />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProcessTreatments