import React from 'react'
import { Link } from 'gatsby'
import LargestLHRBadge from './LargestLHRBadge'

export function HomeHero() {
  return (
    <section className="hero hero-image">
      <div className="container py-lg-5 my-lg-4">
        <div className="row">
          <div className="col offset-xl-2">
            <div className="row">
              <div className="col-md-8 col-lg-6 col-xl-7">
                <h1 className="milan-text-primary">Never Shave Again. Guaranteed.</h1>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-6 col-xxl-5">
                <p>Laser hair removal is a <strong>permanent solution to unwanted hair<sup>&dagger;</sup></strong>. We can remove hair from nearly any part of the body for<strong> less than the cost of a monthly wax</strong>. Treatments are fast and there’s no recovery time (it&nbsp;can be done over lunch!).</p>
              </div>
            </div>
            <div className="row mt-3 gy-3">
              <div className="col-md-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="#works">See How LHR Works</Link>
              </div>
              <div className="col-md-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="/specials/#guarantee">Get Unlimited Treatments</Link>
              </div>
            </div>
          </div>
        </div>
        <span id="works"></span>
      </div>
      <div className="container position-relative">
        <div className="row">
          <div className="col">
            <LargestLHRBadge />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHero