import React from 'react';
import {FaqsForm} from '../../forms';

export function AskNewQuestion({siteData}) {
  return (
    <section id='ask_new_question' className='w-100 py-5'>
      <div className='container '>
        <div className='row milan-text-white align-items-center'>
          <div className='col-lg-4 text-center mb-3'>
            <h5 className='text-capitalize'>Don’t see your question answered?</h5>
            <h3 className='subpointlg'>Ask it here</h3>
          </div>
          <div className='col-lg-8'>
            <FaqsForm siteData={siteData} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AskNewQuestion;
