export const MILAN_SITE_START = 'https://milanlaser';
export const CORPORATE_SITE = MILAN_SITE_START + '.com';
export const GATSBY_DOMAIN = MILAN_SITE_START + 'gatsby.com';
export const MILAN_PHONE = '833-667-2967';
export const NO_RAZOR = '1-833-NO-RAZOR';

// export const GOOGLE_REVIEWS_URL_START = 'https://www.google.com/search?q=milan+laser+hair+removal+'
export const YELP_URL_START =
  'https://www.yelp.com/biz/milan-laser-hair-removal-';
export const FACEBOOK_URL_START = 'https://www.facebook.com/MilanLaser';

export const WEB_TO_LEAD_LINK = 'deprecated';

export const MILAN_CORS = 'https://milan-cors-2023-9de078d0fd3b.herokuapp.com/';
export const NEWSLETTER_FORM_HANDLER =
  MILAN_CORS + process.env.GATSBY_NEWSLETTER_FORM_HANDLER;
export const COUPON_FORM_HANDLER =
  MILAN_CORS + process.env.GATSBY_COUPON_FORM_HANDLER;
export const CUSTOM_QUOTE_FORM_HANDLER =
  MILAN_CORS + process.env.GATSBY_CUSTOM_QUOTE_FORM_HANDLER;
export const FAQS_FORM_HANDLER =
  MILAN_CORS + process.env.GATSBY_FAQS_FORM_HANDLER;
export const STANDARD_INQUIRY_FORM_HANDLER =
  MILAN_CORS + process.env.GATSBY_STANDARD_INQUIRY_FORM_HANDLER;
export const SELF_SCHEDULE_FORM_HANDLER =
  MILAN_CORS + process.env.GATSBY_SELF_SCHEDULE_FORM_HANDLER;
export const CONTACT_FORM_HANDLER = process.env.GATSBY_CONTACT_FORM_HANDLER;

export const FIND_PLACE_GOOGLE_MAPS_URL =
  MILAN_CORS +
  'https://maps.googleapis.com/maps/api/place/findplacefromtext/json';
export const GEOCODE_GOOGLE_MAPS_URL =
  MILAN_CORS + 'https://maps.googleapis.com/maps/api/geocode/json';
export const GOOGLE_REVIEWS_API_URL =
  MILAN_CORS + 'https://maps.googleapis.com/maps/api/place/details/json';

export const FIREBASE_STORAGE_PATH = `https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/`;

export const PLANS_START_AT = '45';
export const WAXING_COST_MONTH = 29;
export const WAXING_COST_YEAR = 23;
export const TREATMENTS_COUNT_MONTH = 30;
export const BRAZILIAN_WAX_COST_LIFETIME = 15;
export const BIKINI_WAX_COST_LIFETIME = 11;

export const BREAKPOINTS = {
  xxs: 0,
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const NETLIFY_FUNCTIONS_PATH = '/.netlify/functions/';
