import React from "react";
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

export function AverageResultsDivider() {
  return (
    <section className="divider-section w-100 py-3 milan-bg-navy">
      <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
        {({ isVisible }) => (
          <div className="container">
            <div className="row justify-content-center milan-text-white text-center">
              <div className="h-100 col-12 col-md-12 d-xl-flex align-items-center justify-content-center">
                <h3 className="subpointlg mb-0 d-inline">
                 clients see <strong><span className="milan-text-secondary width-fluid-1">{isVisible ? <CountUp end={95} start={60} duration={2} /> : 0}</span></strong><strong className="milan-text-secondary">%+</strong> results
                </h3>
                {/* Inline show */}
                <p className="p-subhead d-none d-xl-inline ps-3"> after <strong>7&#8209;10</strong> treatments On average.</p>
                {/* Stacked */}
                <p className="p-subhead d-block d-xl-none mb-0"> after <strong>7&#8209;10</strong> treatments <br className="d-block d-sm-none"/>On average.</p>
              </div>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </section>
  );
}
export default AverageResultsDivider