import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: `${process.env.GATSBY_FIREBASE_API_KEY}`,
  authDomain: "milan-stores-ea6e2.firebaseapp.com",
  projectId: "milan-stores-ea6e2",
  storageBucket: "milan-stores-ea6e2.appspot.com",
  messagingSenderId: "513427623665",
  appId: "1:513427623665:web:6d85c2a94a382e6cdd6350"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
