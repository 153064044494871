import { CORPORATE_SITE } from '../../../utils';
export const MENU_LIST = [
  {
    link: 'Specials',
    pathname: '/specials/',
    subMenu: [
      {
        link: "This Month's Special",
        pathname: '/specials/',
      },
      {
        link: 'Payment Plans',
        pathname: '/specials/#financing',
      },
    ],
  },

  {
    link: 'The Process',
    pathname: '/process/',
    subMenu: [
      {
        link: 'How it works',
        pathname: '/process/',
      },
      {
        link: 'Before/After Photos',
        pathname: '/process/beforeafter/',
        subSubMenu: [
          {
            link: 'Face & Lip',
            pathname: '/process/beforeafter/beforeafter_lip/',
          },
          {
            link: 'Underarms',
            pathname: '/process/beforeafter/beforeafter_underarm/',
          },
          {
            link: 'Bikini',
            pathname: '/process/beforeafter/beforeafter_bikini/',
          },
          {
            link: 'Legs',
            pathname: '/process/beforeafter/beforeafter_legs/',
          },
          {
            link: "Men's Area",
            pathname: '/process/beforeafter/beforeafter_men/',
          },
        ],
      },
      {
        link: 'Candidate Quiz',
        pathname: '/process/quiz/',
      },
      {
        link: 'At-Home Devices',
        pathname: 'https://milanlaser.com/homedevice',
      },
      {
        link: 'FAQs',
        pathname: '/process/faqs/',
      },
      {
        link: 'Types of Hair Removal',
        pathname: '/process/compare/',
      },
    ],
  },

  {
    link: 'Body Areas',
    pathname: '/areas/',
    subMenu: [
      {
        link: 'Lip & Chin',
        pathname: '/areas/lipnchin/',
      },
      {
        link: 'Legs',
        pathname: '/areas/legs/',
      },
      {
        link: 'Underarm',
        pathname: '/areas/underarm/',
      },
      {
        link: 'Bikini Area',
        pathname: '/areas/bikini/',
      },
      {
        link: 'Brazilian Bikini',
        pathname: '/areas/brazilian/',
      },
      {
        link: 'For Men',
        pathname: '/areas/for-men/',
      },
      {
        link: 'For Brown Skin',
        pathname: '/areas/darkskin/',
      },
      {
        link: 'All Body Areas',
        pathname: '/areas/',
      },
    ],
  },

  {
    link: 'Locations',
    pathname: '/locations/',
    subMenu: [
      {
        link: 'Metro Location',
        pathname: '/locations/metro/',
      },
      {
        link: 'All Locations',
        pathname: '/locations/',
      },
      {
        link: 'Contact Us',
        pathname: '/locations/contact/',
      },
      {
        link: 'About Us',
        pathname: '/locations/metro/about-us/',
      },
      {
        link: 'Reviews/Testimonials',
        pathname: '/locations/reviews/',
      },
      {
        link: 'Facebook',
        pathname: '/locations/contact/#facebook',
      },
      {
        link: 'Twitter',
        pathname: 'https://twitter.com/milanlaser',
      },
      {
        link: 'Careers',
        pathname: `${CORPORATE_SITE}/careers/`,
      },
    ],
  },
];
