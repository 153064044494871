import React from 'react'
// import { FaInstagram, FaFacebookSquare, FaTiktok } from 'react-icons/fa'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faSquareFacebook, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FACEBOOK_URL_START } from '../../../utils'
import { useMonth } from '../../../hooks'

export function SocialMedia({ currentMetro }) {
  return (
    <section id="social" className="milan-py background">
      <div className="container">
        <h2 className="subheadlg milan-text-primary text-center">Milan Laser Social Media</h2>
        <div className="row gy-4 mt-1">
          <div className="col-md-6 col-lg-4">
          <div id="facebook">
          <p className="p-subhead text-center">
            <FontAwesomeIcon icon={faSquareFacebook} className="me-1 fs-2" /> See Us on Facebook
              </p>
              <div id="fb-subhead" className="ps-2 pb-3 text-center">
                <h6 className="mb-0 milan-text-primary">Milan Laser Hair Removal</h6>
                <h6 className="">{currentMetro.name}, {currentMetro.stateAbbrev}</h6>
              </div>
              <div id="fb-card" className="container rounded position-relative overflow-hidden">
                <ul className="row overflow-scroll align-content-start gy-2">
                  <li className="col-12 px-0"><figure id="fb-thumbnail" className="rounded-top mb-1"></figure></li>
                  {
                    currentMetro.stores.map((store, x) => (
                      <li key={x} className="col-6">
                        <a
                          className="d-block px-2 py-1 milan-text-primary milan-bg-white rounded"
                          href={`${FACEBOOK_URL_START}${store.platforms.facebook}`} target="_blank">
                          {store.marketingCity}</a>
                      </li>
                    ))
                  }
                </ul>
                <div id="fb-card-footer" className="px-2 bg-white">
                  <p className="milan-fp mb-0"><FontAwesomeIcon icon={faSquareFacebook} className="me-1 fs-2" /> on {useMonth()} {new Date().getDate()}, {new Date().getFullYear()}</p>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div id="tiktok">
            <p className="p-subhead text-center">
            <FontAwesomeIcon icon={faTiktok} className="me-1 fs-2" /> See Us on TikTok
              </p>
              <div className="embedsocial-hashtag" data-ref="cbecc999c626f9d99ecd153174f025b7295b72cb" />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div id="instagram">
            <p className="p-subhead text-center">
            <FontAwesomeIcon icon={faInstagram} className="me-1 fs-2" /> See Us on Instagram
              </p>
              <div className="embedsocial-hashtag" data-ref="c016e7ff10d133a877eb187294c7f917da2fd1f7" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SocialMedia

