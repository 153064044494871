import * as Yup from 'yup';

export const faqsSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('Required')
    .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
  last_name: Yup.string()
    .required('Required')
    .matches(/^(?!\s*$).+$/, 'Only spaces are not allowed'),
  phone: Yup.string().min(15, 'Invalid phone number').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  location: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  updates: Yup.boolean(),
});
