import React, {useContext, useState} from 'react';
import {Header, Footer, Consult, SignUpModal} from '../components';
import {GlobalSiteDataContext} from '../context';
import {hardTrim} from '../utils';
import '../utils/styles/global.css';
import {config} from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

export function Layout({children, location}) {
  const siteData = useContext(GlobalSiteDataContext);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  return (
    <div id={`${location.pathname === '/' ? 'home' : hardTrim(location.pathname)}-page-wrapper`}>
      <Header siteData={siteData} />
      {children}
      <span id='consult'></span>
      <Consult siteData={siteData} />
      {showSignUpModal && <SignUpModal {...{siteData, showSignUpModal, setShowSignUpModal}} />}
      <Footer siteData={siteData} setShowSignUpModal={setShowSignUpModal} />
    </div>
  );
}

export default Layout;
