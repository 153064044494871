import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebookF,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { FACEBOOK_URL_START } from '../../utils';
import { Link } from 'gatsby';

export function FooterSocialIcons({ currentMetro }) {
  return (
    <div className='footer-social-icons d-flex align-items-center'>
      <a
        href='https://www.instagram.com/milanlaser/'
        className='me-4'
        target='_blank'
      >
        <FontAwesomeIcon icon={faInstagram} className='me-1 fs-3' />
      </a>

      <a
        href='https://www.tiktok.com/@milanlaserhairremoval'
        className='me-4'
        target='_blank'
      >
        <FontAwesomeIcon icon={faTiktok} className='me-1 fs-4' />
      </a>

      {currentMetro.stores.length === 1 ? (
        <a
          className='me-4'
          href={`${FACEBOOK_URL_START}${currentMetro.stores[0].platforms.facebook}`}
          target='_blank'
        >
          <FontAwesomeIcon icon={faFacebookF} className='me-1 fs-4' />
        </a>
      ) : (
        <Link to='/locations/contact/#social' className='me-2'>
          <FontAwesomeIcon icon={faFacebookF} className='me-1 fs-4' />
        </Link>
      )}
    </div>
  );
}

export default FooterSocialIcons;
