import React from 'react'
import { Link } from 'gatsby'
import GRAPHIC from '../../../images/specials/hero/Nov24-Badge.svg'

function SocialHeroContent({ setShowSignUpModal }) {
    return (
        <div id="social-hero-content" className="container py-lg-milan-50">
            <div className="row">
                <div className="offset-xs-1 col-lg-6 offset-lg-6">
                    <figure className="d-none d-lg-block pb-4 text-center">
                        <img
                            id="graphic"
                            src={GRAPHIC}
                            alt="Black Friday Bonus Event"
                            style={{ width: '78.65%' }}
                        />
                    </figure>

                    <p className="pt-3 pt-lg-none">
                        It's that time of year! Visit Milan Laser Hair Removal
                        and get <strong>one FREE treatment</strong>
                        <span className="special-asterisk">*</span>. Plus,
                        complete a consultation by <strong>November 30</strong>{' '}
                        to{' '}
                        <strong>
                            get an extra $500 OFF plus 50% OFF ALL body areas
                        </strong>{' '}
                        during our <strong>Black Friday Bonus Event</strong>—no
                        lines, no crowds, just savings!
                    </p>

                    <div className="mt-4 text-center">
                        <Link
                            className="milan-btn milan-cta-btn w-auto"
                            to="#consult"
                        >
                            Book My Consult
                        </Link>
                    </div>
                    <div className="mt-4">
                        <p className="mb-0 milan-fp pb-0">
                            <span
                                className="text-decoration-underline pointer"
                                onClick={() => setShowSignUpModal(true)}
                            >
                                Stay up to date with special discounts and sales
                                by subscribing to our email list.
                            </span>
                            We promise we'll never spam you or sell your
                            information.
                        </p>
                        <p className="pb-milan-50 mt-2 milan-fp">
                            *Call or visit clinic for free treatment details
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialHeroContent
