import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { PLANS_START_AT } from '../../../utils';

export function MilanCredit({ setShowSignUpModal, globalData }) {
  return (
    <section
      id='lhr-specials'
      className='milan-bg-tur milan-credit-bg overflow-hidden'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 col-xxl-6 vrt-divider vrt-divider-turq-alt'></div>
          <div className='col-lg-7 col-xxl-6 milan-bg-tur milan-text-white milan-py bg-extend-right'>
            <div className='row'>
              <div className='col'>
                <h2 className='subheadlg me-xl-3'>
                  The cost of laser hair removal won’t break the bank!
                </h2>
                <p>
                  At Milan, we believe everyone deserves to get smooth,
                  hair-free skin at a price they can afford. That’s why we offer
                  everyone affordable payment plans!
                </p>
                <ul className='list-style-disc text-start lh-lg'>
                  <li>
                    <strong>100%</strong> Credit Approval.
                  </li>
                  <li>
                    <Link
                      to='/specials/estimate/'
                      className='text-decoration-underline fw-bold'
                    >
                      Get a custom quote.
                    </Link>
                  </li>
                  <li>
                    Payments as low as{' '}
                    <strong>
                      $
                      {globalData.plans_start_at
                        ? globalData.plans_start_at
                        : PLANS_START_AT}
                    </strong>
                    /month.
                  </li>
                </ul>
                <p>
                  Don’t forget to check out our{' '}
                  <Link to='/specials/'>specials page</Link> or{' '}
                  <span
                    className='pointer text-decoration-underline'
                    onClick={() => setShowSignUpModal(true)}
                  >
                    <strong>sign up for our newsletter</strong>
                  </span>{' '}
                  for amazing deals!
                </p>
              </div>
            </div>
            <div className='row gy-3 gx-lg-2 gx-xl-4 pt-5'>
              <div className='col-12 col-md-auto text-center'>
                <Link
                  className='milan-btn milan-navy-btn'
                  to='/process/beforeafter/'
                >
                  Before &amp; After
                </Link>
              </div>
              <div className='col-12 col-md-auto text-center'>
                <Link className='milan-btn milan-navy-btn' to='/process/'>
                  Our Technology
                </Link>
              </div>
              <div className='col-12 col-md-auto text-center z-index-100'>
                <Link
                  className='milan-btn milan-navy-btn'
                  to='/process/compare/'
                >
                  Hair Removal Techniques
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='background spacer d-lg-none hr-divider hr-divider-reversed hr-divider-turq-light'></div>
    </section>
  );
}

export default MilanCredit;
