import React from 'react'
import { GATSBY_DOMAIN } from "../../../utils"

export function ProcessHype() {
  return (
    <section className="py-0 hero" style={{marginBottom: '-0.5rem'}}>
      <iframe className="process-hype" src={`${GATSBY_DOMAIN}/hype_animation/process_hype_23.html`} title="Laser hair removal process"></iframe>
    </section>
  )
}

export default ProcessHype