export const quoteInitialValues = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  location: '',
  bodyAreas: [],
  description: '',
};

export const bodyAreas = ['Brazilian', 'Underarm', 'Upper Lip', 'Chin', 'Face & Neck', 'Legs', 'Back', 'Chest'];
