import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export function UnlimitedPackageBenefits() {
  return (
    <section id="unlimited-benefits" className="milan-bg-tur milan-credit-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xxl-6 milan-bg-tur milan-text-white milan-py">
              <h2 className="subheadlg">Benefits of the Unlimited Package</h2>
              {/* <div className="row shift-lg-left"> */}
              <div className="row">
                <div className="col">
                  <p>At Milan, there’s no paying by the session. We want you to love your results and get all the treatments needed for <strong>one affordable price.</strong></p>
                  <p className="mb-0">Our exclusive <strong>Unlimited Package™</strong> comes with every purchase, and <strong>we guarantee your results for life</strong>. If you experience hormone changes or new hairs show up down the road, we’ll take care of it for you at <strong>no additional cost.</strong></p>
                </div>
                <div className="col-md-auto shift-lg-right z-3">
                  <div className="text-center text-md-start mt-4">
                    <StaticImage src="../../../images/shared/Unlimited-Package.png" style={{maxWidth: '200px'}} alt="Legs Laser Hair Removal Photo, Before &amp; After" className="ba-top-bottom-img" />
                  </div>
                </div>
              </div>
              {/* <div className="row gy-3 gx-lg-2 gx-xl-4 pt-5"> */}
              <div className="row gy-3 gx-lg-2 pt-5">
                <div className="col-12 text-center col-md-auto text-md-start py-1"><Link className="milan-btn milan-navy-btn" to="/process/">The Process</Link></div>
                <div className="col-12 text-center col-md-auto text-md-start py-1"><Link className="milan-btn milan-navy-btn long" to="/process/faqs/">Laser Hair Removal FAQs</Link></div>
                <div className="col-12 text-center col-md-auto text-md-start py-1 z-index-100"><Link className="milan-btn milan-navy-btn" to="/locations/about-us/">About Milan Laser</Link></div>
              </div>
            </div>
            <div className="col-lg-5 col-xxl-6 vrt-divider vrt-divider-reversed vrt-divider-turq" />
          </div>
        </div>
        <div className="background spacer d-lg-none mb-0 hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
      </section>
  )
}
export default UnlimitedPackageBenefits