import React from 'react'

export function LocationsDropdown({milanStores}) {
  
  return (
    <>
    <optgroup>
      <option value="">Select a location</option>
    </optgroup>
    {
      milanStores.length !== 0 && milanStores.map((item, i) => (
        <optgroup key={i} label={item.name}>
          {
            item.metros.map(metro => {
              let option = metro.stores.map((store, i) => {
                return (
                  <option key={i} value={store.salesforceValue} zip={store.zipCode}>
                    {/* original */}
                    {/* {metro.name} {store.marketingCity.includes(metro.name) ? store.marketingCity.slice(metro.name.length + 1) : `(${store.marketingCity})`} {store.open === false ? '/ Coming Soon' : ''} */}
                    {/* fix */}
                    {metro.name} {store.marketingCity.includes(metro.name) ? store.marketingCity.replace(metro.name, '').trim() : `(${store.marketingCity})`} {store.open === false ? '/ Coming Soon' : ''}
                  </option>
                )})
              return option
            })
          }
        </optgroup>
      ))
    }
    </>
  )
}

export default LocationsDropdown
