import React from 'react'
import { Link } from 'gatsby'

function QuizResult({ startQuiz, result, quizResults }) {
  return (
    <div className="quiz-result-wrapper">
      <div className="quiz-result p-3 rounded">
        <p className="mb-0">{result}</p>
        {
          result === quizResults.between_16_18 &&
          <p className="mb-0 mt-2 lh-1"><small>{quizResults.guardian}</small></p>
        }
      </div>
      <div className="text-center mt-4 mb-1">
        <Link to='#consult' className="milan-btn milan-red-btn">Book Your Free Consult</Link>
      </div>
      <ul className="row justify-content-center gy-2 gx-5 ps-0 mt-1 mt-md-4">
        <div className="col-md-auto text-center">
          <Link to='/process/faqs/' className="milan-btn milan-grey-btn d-md-none">Frequently Asked questions</Link>
          <Link to='/process/faqs/#ask_new_question' className="d-none d-md-inline text-decoration-underline milan-text-primary fw-bold">Ask A Question</Link>
        </div>
        <div className="col-md-auto text-center">
          <Link to='/process/faqs/#ask_new_question' className="milan-btn milan-grey-btn d-md-none">Ask Your Own Question</Link>
          <Link to='/process/faqs/' className="d-none d-md-inline text-decoration-underline milan-text-primary fw-bold">FAQs</Link>
        </div>
        <div className="col-md-auto text-center">
          <span className="d-none d-md-inline text-decoration-underline milan-text-primary fw-bold pointer" onClick={startQuiz}>Take Quiz Again</span>
          <span className="milan-btn milan-grey-btn d-md-none" onClick={startQuiz}>Take Quiz Again</span>
        </div>
      </ul>
      <div>
        <p className="lh-1 pt-3"><small>While this is not an exhaustive list of factors to think about when considering laser hair removal, it should give you a better understanding of whether or not laser hair removal is a good option for you. <strong>Terms:</strong> This information does not constitute medical advice. We recommend completing a full consultation with an expert to make a final determination of your laser hair removal eligibility.</small></p>
      </div>
    </div>
  )
}

export default QuizResult