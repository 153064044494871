import React from 'react';

export function FooterSignup({ setShowSignUpModal }) {
  return (
    <div className='px-0'>
      <div className='row'>
        <div className='col-12 text-center'>
          <button
            className={
              'milan-btn milan-secondary-btn w-100 w-md-50 w-lg-100 success'
            }
            onClick={() => setShowSignUpModal(true)}
          >
            KEEP UP WITH US
          </button>
        </div>
      </div>
    </div>
  );
}

export default FooterSignup;
