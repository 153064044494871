import React, { useState, useEffect } from 'react'
import { StoreHeroDetails } from '.'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { hardTrim, BREAKPOINTS } from '../../../utils'
import useBreakpoint from 'use-breakpoint'
import DEFAULT_BG_IMG from '../../../images/locations/mapbackground.webp'

export function StoreHero({ currentMetro, currentStore }) {
  const Exterior_Temp_Img = useStaticQuery(graphql`
		query Exterior_Temp_Img {
			allFile(
				filter: {sourceInstanceName: {eq: "localImages"}}
			) {
        edges {
          node {
            base
            name
            childImageSharp {
              gatsbyImageData(quality: 90, placeholder: BLURRED, webpOptions: {quality: 90})
            }
          }
        }
      }
		}
	`).allFile.edges.find(item => item.node.name.toLowerCase() === hardTrim(currentStore.marketingCity).toLowerCase() + '_portrait')

  const { minWidth } = useBreakpoint(BREAKPOINTS, 'xxs')

  const PRO_IMAGES = currentStore.storeImages
  const [withProImages, setWithProImages] = useState(currentStore.with_pro_images)
  useEffect(() => {
    if (PRO_IMAGES.hero) {
      setWithProImages(true)
    }
  }, [PRO_IMAGES])

  useEffect(() => {
    // In case with_pro_images prop updated in the remote
    if(withProImages !== currentStore.with_pro_images) {
      setWithProImages(currentStore.with_pro_images)
    }
  }, [currentStore.with_pro_images])

  return (
    <section
      className={`hero lh-base py-0 milan-bg-primary-sm ${withProImages ? 'store-hero_pro' : 'store-hero_basic'}`}
      style={{
        backgroundImage: (minWidth >= BREAKPOINTS.xxl && PRO_IMAGES.heroxl) ? `url(${PRO_IMAGES.heroxl})` : (minWidth >= BREAKPOINTS.sm && PRO_IMAGES.hero) ? `url(${PRO_IMAGES.hero})` : `url(${DEFAULT_BG_IMG})`,
        backgroundPosition: PRO_IMAGES.hero ? currentStore.hero.position : 'center center'
      }} >

      <div className="d-sm-none hero-thumbnail">
        {
          (withProImages || PRO_IMAGES.thumbnail) ?
            <img src={PRO_IMAGES.thumbnail} className="w-100" alt="" />
            : <StaticImage src="../../../images/locations/coming-soon-cam-sm.webp" className="h-100" alt="Coming soon" />
        }
      </div>
      <div className="container px-0">
        <div
          className={`d-flex g-0 transition flex-column flex-md-row-reverse justify-content-start justify-content-md-end`}>
          {
            !withProImages &&
            <div className={`hero-column portrait overflow-hidden`}>
              {
                Exterior_Temp_Img ?
                  <GatsbyImage
                    className="w-100 store-exterior"
                    imgClassName="w-100"
                    image={getImage(Exterior_Temp_Img.node)}
                    alt={`Milan Laser ${currentStore.marketingCity} Exterior`} />
                  :
                  PRO_IMAGES.portrait ?
                    <img src={PRO_IMAGES.portrait} className="w-100 store-exterior" alt={`Milan Laser ${currentStore.marketingCity} Portrait`} />
                    :
                    <StaticImage src="../../../images/locations/coming-soon-cam.webp" className="h-100" alt="Coming soon" />
              }
            </div>
          }
          <div className={`hero-column hero-details-wrapper transition ${withProImages && currentStore.hero.reversed ? 'with-hero-reversed' : ''} ${withProImages && !currentStore.hero.reversed ? 'with-hero' : ''}`}>
            <StoreHeroDetails currentStore={currentStore} currentMetro={currentMetro} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default StoreHero
