import React from 'react'
import './SpecialsHero.css'
import SpecialsHeroContent from './SpecialsHeroContent'

export const HOME_PAGE_COPY = {
    default: 'Black Friday Bonus: |Get an extra $500 off + 50% OFF!',
    grandOpening: 'Grand Opening Sale: | Get an extra $500 off + 50% OFF!',
}

export function SpecialsHero({ siteData, setShowSignUpModal }) {
    return (
        <section id="specials-hero" className="hero position-relative py-0">
            <div className="d-lg-none stacked-hero-bg" />
            <div className="d-lg-block hero-bg">
                <SpecialsHeroContent
                    siteData={siteData}
                    setShowSignUpModal={setShowSignUpModal}
                />
            </div>
        </section>
    )
}
export default SpecialsHero
