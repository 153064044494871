import React from 'react'
import { Link } from 'gatsby'
const Legs_BA_Img = 'https://firebasestorage.googleapis.com/v0/b/milan-stores-ea6e2.appspot.com/o/assets%2Fareas%2Flegs%2FLegs_Top-Bottom.jpg?alt=media&token=85724d0a-367d-4d36-bd0d-602a1be1ce99'

export function HowLegWork() {
  return (
<section id="how-legs-work" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          {/* <div className="col-xl-7 milan-bg-tur milan-text-white py-xl-milan-50"> */}
          <div className="col-xl-7 milan-bg-tur milan-text-white py-milan-50">
            <div className="row">
              <div className="col-auto">
                <h2 className="subheadlg">How Leg Laser Hair Removal Works</h2>
              </div>
              <div className="col-12 col-md-8 z-index-100 order-2 order-md-1">                
                <p className='d-none d-sm-block'>No one likes to look at razor burn, bumps, cuts, and ingrown hairs on their legs. With laser hair removal, those worries are gone! Your legs will feel softer and look flawless, free of skin imperfections or irritations. Start your journey to smooth at Milan Laser Hair Removal!</p>
                <p>A laser pulse targets pigment in the hair follicle and destroys the root without damaging or irritating your skin. Our lasers work with cooling technology to reduce any potential discomfort. With the root destroyed and the hair growth cycle disrupted, that hair will never grow again, giving you smooth, hair-free legs. <Link to="/process/" className="">See a demo</Link></p>
                <p className="d-none d-sm-block">Treatments are quick, and you must shave beforehand for optimal results. Unlike electrolysis or waxing, there’s no need to deal with stubble between treatments. </p>
              </div>
              <div className="col-12 col-md-4 col-lg-3 col-xl-4 order-1 order-md-2 text-center z-index-100 pb-4 pb-md-0">
                <img src={Legs_BA_Img} alt="Legs Laser Hair Removal Photo, Before &amp; After" className="ba-top-bottom-img" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 vrt-divider vrt-divider-reversed vrt-divider-turq" />
        </div>
      </div>
      <div className="spacer d-xl-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    </section>
  )
}

export default HowLegWork