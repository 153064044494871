import React from 'react'
import { Link } from 'gatsby'
import GRAPHIC from '../../../images/specials/hero/Nov24-Badge.svg'
import './SpecialsHero.css'

function SpecialsHeroContent({ siteData, setShowSignUpModal }) {
    return (
        <div id="specials-hero" className="position-relative pt-0">
            <div className="container py-lg-milan-50 position-relative">
                <div className="row">
                    <div className="offset-lg-6 col-lg-6 pt-4">
                        {/* only hide on md */}
                        <figure className="pb-4 position-relative z-index-100 text-center d-none d-lg-block">
                            <img
                                id="graphic"
                                src={GRAPHIC}
                                alt="Milan Laser Social"
                            />
                        </figure>
                        <h1 className="smalltitle text-uppercase pb-0 milan-text-primary d-block text-center">
                            Laser Hair Removal Specials
                        </h1>
                        <h5 className="pb-3 d-block text-uppercase text-center milan-text-primary">
                            in {siteData.currentMetro.name}
                        </h5>
                        {siteData.currentMetro.grandOpening ? (
                            <p>
                                It’s that time of year! This month during our
                                Grand Opening, complete a consultation by
                                November 30 and{' '}
                                <strong>
                                    get an extra $500 OFF plus 50% OFF ALL body
                                    areas
                                </strong>{' '}
                                during our Black Friday Bonus Event—no lines, no
                                crowds, just savings.
                                <Link
                                    to="#guarantee"
                                    className="milan-text-primary"
                                >
                                    exclusive Unlimited Package™
                                </Link>{' '}
                                comes with every purchase and includes FREE
                                touch-ups, so you’re covered for life at any of
                                our 380+ locations nationwide.
                            </p>
                        ) : (
                            <>
                                <p>
                                    It's that time of year! Complete a
                                    consultation by November 30 and{' '}
                                    <strong>
                                        get an extra $500 OFF plus 50% OFF ALL
                                        body areas
                                    </strong>{' '}
                                    during our Black Friday Bonus Event—no
                                    lines, no crowds, just savings.
                                </p>
                                <p>
                                    Our exclusive{' '}
                                    <Link
                                        to="#guarantee"
                                        className="milan-text-primary"
                                    >
                                        Unlimited Package™
                                    </Link>{' '}
                                    comes with every purchase, so it's easy as
                                    pie to get smooth skin for life without the
                                    extra fees.
                                </p>
                            </>
                        )}
                        {/* Desktop Only */}
                        <p className="d-none d-md-block">
                            <strong>An Extra Helping: </strong> Receive a{' '}
                            <strong>FREE TREATMENT*</strong> when you complete a
                            complimentary consultation this month.
                        </p>
                        {/* Mobile Only */}
                        <p className="d-md-none">
                            <strong>An Extra Helping: </strong> Receive a{' '}
                            <strong>FREE TREATMENT*</strong> when you complete a
                            complimentary consultation this month.
                        </p>
                        <p>
                            Ready to <em>squash</em> unwanted stubble? Head to
                            your{' '}
                            <Link
                                to="/locations/"
                                className="milan-text-primary"
                            >
                                local Milan Laser
                            </Link>{' '}
                            by <strong>November 30</strong> to save big and
                            feast your eyes on smooth skin!
                        </p>
                        <div className="my-4 text-center">
                            <Link
                                className="milan-btn milan-cta-btn"
                                to="#consult"
                            >
                                Book My Consult
                            </Link>
                        </div>
                        <p className="mb-lg-0 milan-fp">
                            <span
                                className="text-decoration-underline pointer"
                                onClick={() => setShowSignUpModal(true)}
                            >
                                <strong>
                                    Stay up to date with special discounts and
                                    sales by subscribing to our email list.
                                </strong>
                            </span>
                            <span>
                                {' '}
                                We promise we'll never spam you or sell your
                                information.
                            </span>
                        </p>
                        <p className="mb-lg-0 mt-2 milan-fp">
                            *Call or visit clinic for free treatment details
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecialsHeroContent
