import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export function PorcessReviewThree() {
  return (
    <section className="position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="quote-circle" src="../../../images/process/home/quote-circle.png" imgClassName="middle-icon" />
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center text-with-quotes">
            <figure className="text-center px-2 mb-0 limited-width">
              <blockquote>At Milan, it's for life.. Yes, for life. You purchase a "body part" and even if the hair returns 10 years down the road, you are still covered.</blockquote>
              <figcaption><strong>- Therese Merkel</strong></figcaption>
            </figure>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center milan-py-lg">
            <StaticImage className="reviewer-img" alt="Therese Merkel Review" src="../../../images/process/home/therese.webp" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PorcessReviewThree