import React, { useState } from 'react'
import { Link } from 'gatsby'
// import { FaPhone } from 'react-icons/fa'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faPhoneFlip } from "@fortawesome/free-solid-svg-icons";
import useBreakpoint from 'use-breakpoint'
import { NO_RAZOR, MILAN_PHONE, BREAKPOINTS } from '../../utils'
import { useClickOutside } from '../../hooks'
import { Navbar, resetMenu } from '.'
import { MilanLogo } from "../shared"
import { HOME_PAGE_COPY } from '..'
import './Header.css'

const ConsultBtn = ({ siteData }) => (
  <Link to='#consult' className="milan-btn consult-btn w-auto">
    {siteData.currentMetro.openStores === 0 ? 'Request More Information' : 'FREE CONSULT'}
  </Link>
)

export function Header({ siteData }) {
  // Reset the menu: useful when navigating between the pages
  resetMenu(siteData.menu)
  const [isOpen, setIsOpen] = useState(false)
  // Close/Open the main menu
  const handleClick = () => setIsOpen(!isOpen)
  // Close main menu when click outside the Header component
  const domNode = useClickOutside(() => setIsOpen(false))

  const { minWidth } = useBreakpoint(BREAKPOINTS, 'xxs')

  return (
    <header ref={domNode}>
      <section id="nav-section">
        <div className="container">
          <div className="row">
            <nav>
              <Link to="/" className="d-inline-block nav-logo py-4" onClick={() => setIsOpen(false)}>
                <MilanLogo parentClasses="d-inline-block d-md-none" type="white" />
                <MilanLogo parentClasses="d-none d-md-inline-block me-lg-3" imgClasses="mb-lg-2" type="white_wide" />
              </Link>
              <div id="burger-bars" className={`d-inline-block d-lg-none ${isOpen ? 'change' : ''}`} onClick={handleClick} role="button">
                <div className="bar bar1"></div>
                <div className="bar bar2"></div>
                <div className="bar bar3"></div>
                <div className="bar bar4"></div>
              </div>
              {/* Main menu list */}
              <Navbar
                menu={siteData.menu}
                closeMenu={() => setIsOpen(false)}
                navbarSize={`${minWidth >= BREAKPOINTS.lg ? 'navbar__menu--lg' : 'navbar__menu--sm'}`}
                isOpen={isOpen ? 'isOpen' : ''}
              />
              <div className="float-end nav-cta-btns my-md-4 py-lg-1" onClick={() => setIsOpen(false)}>
                <div className="header-tel d-none d-md-inline-block">
                  <a href={`tel:${MILAN_PHONE}`}>
                    <em><FontAwesomeIcon icon={faPhoneFlip} className="fs-4"/></em><span className="ps-2">{NO_RAZOR}</span>
                    {/* <span className="ps-2">{NO_RAZOR}</span> */}
                  </a>
                </div>
                <div className="d-inline-block d-md-none">
                  <ConsultBtn siteData={siteData} />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
      {
        !isOpen &&
        <section id="banner-section">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <Link to="/specials/" className="milan-text-navy me-md-4">
                  <span className="bar text-uppercase">
                    {
                      siteData.currentMetro.openStores === 0 ? <strong>Coming Soon!</strong>
                        : !siteData.currentMetro.grandOpening ? <><strong>{HOME_PAGE_COPY.default.split('|')[0].trim()}</strong> <span className="d-block d-sm-inline">{HOME_PAGE_COPY.default.split('|')[1].trim()}</span></>
                          : <><strong>{HOME_PAGE_COPY.grandOpening.split('|')[0].trim()}</strong> <span className="d-block d-sm-inline">{HOME_PAGE_COPY.grandOpening.split('|')[1].trim()}</span></>
                    }
                  </span>
                </Link>
                <div className="d-none d-md-inline">
                  <ConsultBtn siteData={siteData} />
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </header>
  )
}

export default Header
