export const initialFormData = {
  metro: {},
  user: {},
  store: {},
  include: {
    formAction: '',
    formProgress: '',
    leadsource: '',
    campaignId: '',
  },
};

export const initialUserData = {
  first_name: '',
  last_name: '',
  phoneMask: '',
  email: '',
  description: '',
  updates: true,
};

export const FORM_ACTIONS = {
  question: 'Question',
  selfSchedule: 'Self Schedule',
};

export const FORM_PROGRESS = {
  initiateQuestion: 'Initiate Question',
  initiateSchedule: 'Initiate Schedule',
  selectLocation: 'Select Location',
};
