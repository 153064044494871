import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export function ProcessReviewFour() {
  return (
    <section className="position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="quote-circle" src="../../../images/process/home/quote-circle.png" imgClassName="middle-icon" />
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center text-with-quotes">
            <figure className="text-center px-2 mb-0 limited-width">
              <blockquote>I felt so good and refreshed after my first treatment— it wasn’t just knowing I’ll never have to shave again, but because <strong>I finally did something for myself</strong> ...</blockquote>
              <figcaption><strong>- Jessica Burgess</strong></figcaption>
            </figure>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center milan-py-lg">
            <StaticImage className="reviewer-img" alt="Jessica Burgess Review" src="../../../images/process/home/jessica.webp" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProcessReviewFour