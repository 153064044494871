import React from "react"
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { BRAZILIAN_WAX_COST_LIFETIME } from '../../../utils'

export function BrazilianWaxCostDivider() {
  return (
    <section className="divider-section w-100 py-3 py-lg-4 milan-bg-navy">
      <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
        {({ isVisible }) => (
          <div className="container">
            <div className="row justify-content-center milan-text-white text-center pt-1">
            <div className="col-12 col-lg-10">
            <h3 className="subpointlg">
                  A Lifetime of Brazilian Waxes Cost <strong><span className="milan-text-tur width-fluid-0">${isVisible ? <CountUp end={BRAZILIAN_WAX_COST_LIFETIME} duration={2} /> : 0}</span></strong><strong className="milan-text-tur">,000</strong>
                </h3>
            </div>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </section>
  );
}
export default BrazilianWaxCostDivider