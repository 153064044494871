import { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore"
import { db } from '../firebase/firebase'

export function useGlobalData() {
  const [globalData, setGlobalData] = useState({})
  const FIREBASE_COLLECTION = 'global-data'
  const DOC_ID = "q5lPpC1u4tlBij8siO1Q"
  const docRef = doc(db, FIREBASE_COLLECTION, DOC_ID);

  useEffect(() => {
    getDoc(docRef).then(doc => {
      if (doc.exists) {
        setGlobalData(doc.data())
      } else {
        console.log('No such document: globalData')
      }
    }).catch(error => {
      console.log('Error fetching global data', error)
    })
  }, [])

  return globalData
}
export default useGlobalData 