import React from 'react'
import { Field } from 'formik'

function RadioAnswers(props) {
  const { label, name, options, ...rest } = props
  return (
    <>
      {/* <label htmlFor={name}>{label}</label> */}
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            return options.map(option => {
              return (
                <li key={option.key} className="col-lg-6 quiz-answer-btn text-center">
                  <input
                    type='radio'
                    id={`${name}.${option.key}`}
                    className="btn-check"
                    autoComplete="off"
                    {...field}
                    value={option.key}
                    checked={field.value == option.key} />
                  <label htmlFor={`${name}.${option.key}`} className={`${field.value == option.key ? 'active' : ''}`}>{option.value}</label>
                </li>
              )
            })
          }
        }
      </Field>
      {/* <ErrorMessage name={name} component={TextError} /> */}
    </>
  )
}


export default RadioAnswers