import { useState, useEffect } from 'react';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage'
import { storage } from "../firebase/firebase"
import { trimAll } from '../utils'

export function useStaffImages(metro, store) {
  const storagePath = `stores/${trimAll(metro.state).toLowerCase()}/${metro.pathname}/${store.pathname}/staff/`
  const staffImagesRef = ref(storage, storagePath)
  const [staffImages, setStaffImages] = useState([]) // Array

  useEffect(() => {
    // To avoid duplications
    setStaffImages([])
    const getStaffImages = () => {
      listAll(staffImagesRef).then(res => {
        if (res.items.length > 0) {
          res.items.forEach(item => {
            let imageRef = ref(storage, `${storagePath}${item.name}`)
            let employee = {}
            const promises = []
            promises.push(getMetadata(imageRef), getDownloadURL(item))
            Promise.all(promises).then(responseArr => {
              // responseArr will be [{metadata}, 'url'] for each employee item
              employee.displayName = responseArr[0].customMetadata.displayName
              employee.position = responseArr[0].customMetadata.position
              employee.index = responseArr[0].customMetadata.index
              employee.showOnWebsite = responseArr[0].customMetadata.showOnWebsite
              employee.imgName = item.name
              employee.url = responseArr[1]
              if(employee.showOnWebsite === undefined || employee.showOnWebsite == 'true') setStaffImages(prev => [...prev, employee])
            }).catch(err => {
              console.log(err)
            })
          })
        } else {
          setStaffImages([])
        }
      }).catch(err => {
        console.error('Error in listAll in getStaffImages', err)
      })
    }
    if(staffImages.length === 0) {
      getStaffImages()
    }
  }, [staffImagesRef._location.path])

  return staffImages.sort((a, b) => Number(a.index) - Number(b.index))
}
export default useStaffImages