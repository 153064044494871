import React, {useState} from 'react'
import { Link } from '../../utils'
import { SubSubMenu, updateSubMenuExpansion } from '.'
// import { FaFacebookSquare, FaTwitter } from 'react-icons/fa'
// import { RiArrowDownSLine, RiArrowUpSLine, RiArrowRightSLine } from 'react-icons/ri'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {faSquareFacebook, faTwitter} from "@fortawesome/free-brands-svg-icons";

export function SubMenu({menu, menuItem, navbarSize, expanded, index, closeMenu}) {

  const [clicked, setClicked] = useState(false)

  const handleClick = (subMenuItem) => {
    updateSubMenuExpansion(menu, subMenuItem, index)
    // this will trigger this component to rerender
    setClicked(!clicked)
  }

  return (
    <div className="subMenu__wrapper">
    { /* menuItem ex: Locations -> subMenuItems : {All Locations, Contact Us, About Us, ...etc} */
      menuItem?.subMenu && 
      <ul className={`subMenu ${expanded ? 'expanded' : ''}`}> 
        {
        menuItem.subMenu.map((subMenuItem, x) => {
          return (
          <li key={x}> {/* Ex: <li>All Locations</li> */}
            <Link to={subMenuItem.pathname} onClick={closeMenu}>
              {/* {subMenuItem.link === 'Facebook' ? <i className="me-2"><FaFacebookSquare /></i>  : */}
              {subMenuItem.link === 'Facebook' ? <em className="me-2"><FontAwesomeIcon icon={faSquareFacebook} /></em>  :
              subMenuItem.link === 'Twitter' ? <em className="me-2"><FontAwesomeIcon icon={faTwitter} /></em>  : null}
              {subMenuItem.link}
            </Link>
            {
              subMenuItem?.subSubMenu &&
              <>
              {
                navbarSize === 'navbar__menu--lg' && 
                <span className="caret__icon caret__icon--right"><FontAwesomeIcon icon={faChevronRight} /></span>
              }
              {
                navbarSize === 'navbar__menu--sm' &&
                <span className="caret__icon" onClick={() => handleClick(subMenuItem)}>
                  { subMenuItem.expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </span>
              }
              {/* Ex: <li>Omaha Central</li> - <li>Omaha West</li>  */}
              <SubSubMenu { ...{subMenuItem, closeMenu} } expanded={subMenuItem.expanded} />
              </>
            }
          </li>
          )
        })
        }        
      </ul> 
    }
  </div>
  )
}

export default SubMenu
