import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import EXPECT_ICON from '../../../images/process/home/icon-what-to-expect.webp'

export function WhatToExpect() {
  return (
    <section id="what-to-expect" className="background position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="arrow-right" src="../../../images/process/home/arrow-right.png" imgClassName="middle-icon" />
        <div className="row flex-sm-row-reverse">
          <div className="col-md-6">
            <div className="process-step milan-text-white text-center mx-auto px-sm-4 px-md-5">
                <h3 className="subpointlg">What to Expect</h3>
                <h3 className='subpointsm'>
                At Milan Laser Hair Removal</h3>
              <p className="my-4">At Milan, you’re never just a number or another face—your Milan team will know your name and get to know
                you during the process. They’ll understand your goals and why laser hair removal is important to you.</p>
              <p className="mb-0">Here’s what you can expect throughout your hair-free journey with us.</p>
            </div>
          </div>
          <div className="col-md-6 border-right">
            <figure className="text-center h-100 d-flex align-items-center justify-content-center pb-4 pb-md-0">
              <img alt="What to expect at Milan Laser Hair Removal" src={EXPECT_ICON} />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatToExpect