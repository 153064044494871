import React from 'react'
import CountUp from 'react-countup'
import { FindMilanNearYou } from "."

export function LocationsHero({ siteData }) {
  return (
    <section className="hero background light-gray-bg">
      <div className="container-fluid px-0">
        <div className="row g-0 justify-content-center">
          <div className="col-sm-11 col-md-10 col-lg-9 col-xl-7 col-xxl-6">
            <div className="card rounded-0 border-0 mx-auto">
              <div className="card-body p-0">
                <h1 className="mt-5 mb-0 text-center milan-text-primary smalltitle">
                  <span className="width-fluid-3">
                    <CountUp end={siteData.milanOpenStores.countToDisplay} duration={2} />
                  </span>
                  + Milan Locations in <span className="width-fluid-2">
                    <CountUp end={siteData.milanStores.length ? siteData.milanStores.length : 23} duration={1} /></span> States</h1>
                {siteData.milanStores.length > 0 && siteData.currentMetro && <FindMilanNearYou siteData={siteData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="triangle-divider"></div>
    </section>
  )
}

export default LocationsHero