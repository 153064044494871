import React from 'react'
import { ContactForm } from '../../forms'
import { MILAN_PHONE, NO_RAZOR } from '../../../utils'

export function ContactHero({siteData}) {
  return (
    <section className="background hero contact-hero">
      <div className="container">
        <h1 className="milan-text-primary text-center mb-4">Contact Us</h1>
        <div className="row">
          <div className="col-md-4 col-lg-6"></div>
          <div className="col-11 col-md-8 col-lg-6 shadow-sm mx-auto bg-transparent-7">
            <div className="text-center">
              <h2 className="mt-3 py-3 subheadsm">Milan Laser | {siteData.currentMetro.name}, {siteData.currentMetro.stateAbbrev}</h2>
              <h5 className="ribbon text-capitalize">Call Us <a href={`tel:${MILAN_PHONE}`} className="milan-text-white stretched-link">{NO_RAZOR}</a></h5>
              <span name="askQ" id="askQ" ></span>
              <p className="mt-4 mb-2 p-subhead">To Re-schedule a Treatment
              {
                siteData.currentMetro.stores.length === 1 && 
                <span>:&nbsp;
                  <a href={`tel:${siteData.currentMetro.stores[0].phone !== '' ? siteData.currentMetro.stores[0].phone : MILAN_PHONE}`} 
                    className="milan-text-primary d-block d-md-inline">
                  {`${siteData.currentMetro.stores[0].phone !== '' ? siteData.currentMetro.stores[0].phone : MILAN_PHONE}`}
                  </a>
                </span>
              }
              </p>
              {
                siteData.currentMetro.stores.length > 1 &&
                <ul className="row mx-0">
                  {
                    siteData.currentMetro.stores.map((store, x) => (
                    <li key={x} className="col-lg-6 text-center text-lg-start">
                      <span className="">{store.marketingCity}:&nbsp;
                        <a href={`tel:${store.phone !== '' ? store.phone : MILAN_PHONE}`} className="milan-text-primary">
                        {`${store.phone !== '' ? store.phone : MILAN_PHONE}`}
                        </a>
                      </span>
                    </li>
                    ))
                  }
                </ul>
              }
            </div>

            <p className="text-center mt-3 p-subhead">Or Send Us a Question</p>
            <ContactForm siteData={siteData} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactHero