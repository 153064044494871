import React, { useRef, useEffect, useState } from "react"
import useBreakpoint from 'use-breakpoint'
import Split from "react-split"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BREAKPOINTS } from "../../../utils"

export function SplitComp({ area }) {
  const ba_container_ref = useRef()
  const [childWidth, setChildWidth] = useState('200%')
  const { minWidth } = useBreakpoint(BREAKPOINTS, 'xxs')

  useEffect(() => {
    if (ba_container_ref.current) {
      // IMPORTANT: this is the width of the parent (ba-container)
      // Always make sure the children have the same width of the parent.
      // Subtracting 10 from the offsetWidth seems to be fitting better
      setChildWidth(ba_container_ref.current.offsetWidth - 10)
    }
  }, [ba_container_ref.current, childWidth, minWidth])

  return (
    <div className="row mt-3">
      <div className="col-lg-8 col-xl-7 m-auto">
        <div ref={ba_container_ref} className="ba-container w-100">
          {
            area.ba_photos.map((subArr, x) => (
              // NOTE: sizes should be [50, 50] but the right image seems to be vibrating with every drag on Chrome
              // Setting sizes to [51, 50] seems to be fixing the problem
              // Updated Note: [50, 50] seems to be working fine now
              <Split key={x} sizes={[50, 50]} minSize={10} snapOffset={10} gutterSize={5} className="ba-slider d-flex">
                <div className="overflow-hidden">
                  <GatsbyImage
                    className="h-100"
                    style={{ width: childWidth }}
                    image={getImage(subArr[1].node)}
                    alt={`Before and after preview for ${area.area}`} />
                </div>
                <div className="overflow-hidden position-relative img-after d-flex justify-content-end">
                  <GatsbyImage
                    className="h-100 position-absolute"
                    style={{ width: childWidth }}
                    image={getImage(subArr[0].node)}
                    alt={`Before and after preview for ${area.area}`} />
                </div>
              </Split>
            ))
          }
        </div>
      </div>
    </div>
  );
}
export default SplitComp;
