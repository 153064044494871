import React from "react";
import {Link} from "gatsby";

export function MenChestAndStomach() {
  return (
    <section id="men-chest-stomach" className="pt-milan-50 py-md-milan-50 py-lg-milan-150">
      <div className="container">
        <div className="row">
          <div className="offset-md-5 col-md-7 offset-lg-6 col-lg-6">
            <h2 className="subheadlg milan-text-primary">Chest &amp; Stomach Laser Hair Removal</h2>
            <p>
              A hairy chest or stomach can be uncomfortable; it makes you hot and makes wearing low-cut shirts a headache. The time-consuming task of shaving can leave cuts, burns,
              or ingrown hairs, making a simple pool day a nightmare.
            </p>
            <p>
              It’s time for a permanent solution so you never shave again. With laser hair removal, you can start your day without hair holding you back from a confident, clean
              stomach or chest free of body hair for the rest of your life. Don’t let hair continue to be a nuisance or burden your days, your solution to smooth has arrived.
            </p>
            <p>
              <Link to="#consult" className="milan-text-primary">
                Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* bg when stacked */}
      <div className="d-md-none stack-hero-bg mt-2" />
    </section>
  );
}

export default MenChestAndStomach;

// <section id="men-chest-stomach" className="full-section background">
//   <div className="container">
//     <div className="row mt-lg-4 pt-3">
//       <div className="col-md-6 anchor-blue">
//       <h2 className="subheadlg">Chest &amp; Stomach Laser Hair Removal</h2>
//         <p>A hairy chest or stomach can be uncomfortable; it makes you hot and makes wearing low-cut shirts a headache. The time-consuming task of shaving can leave cuts, burns, or ingrown hairs, making a simple pool day a nightmare. </p>
//         <p>It’s time for a permanent solution so you never shave again. With laser hair removal, you can start your day without hair holding you back from a confident, clean stomach or chest free of body hair for the rest of your life. Don’t let hair continue to be a nuisance or burden your days, your solution to smooth has arrived. </p>
//         <Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link>
//       </div>
//     </div>
//   </div>
// </section>
