import { useState, useEffect } from 'react';
import { collection, onSnapshot  } from "firebase/firestore"
import { db } from '../firebase/firebase';

export function useMedicalDirectors() {
  const FIREBASE_COLLECTION = 'medical-directors'
	const [medicalDirectors, setMedicalDirectors] = useState([])

  useEffect(() => {
		const unsubscribe = onSnapshot(collection(db, FIREBASE_COLLECTION), (snapshot) => {
			const fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}))
			setMedicalDirectors(fetchedData)
		}, error => {
			console.error(error)
		});
		// Stop listening to changes
		return unsubscribe
	}, [])
  return medicalDirectors.sort((a, b) => a.displayName.localeCompare(b.displayName))
}
export default useMedicalDirectors