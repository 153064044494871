import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import RadioAnswers from './RadioAnswers';
import { questions } from './quizConstants';

const QuizCarousel = ({ questionIndex }) => (
  <Carousel
    // onSelect={handleSelect}
    activeIndex={questionIndex}
    interval={null}
    controls={false}
    indicators={false}
    keyboard={false}
    className="milan-border-radius p-3"
  >
    {
      questions.map((q, x) => (
        <Carousel.Item key={x}>
          <h5 className="mb-3 text-capitalize">{questionIndex + 1}. {q.question}</h5>
          <ul className="row gy-2 gx-3 mb-0 ps-0">
            <RadioAnswers options={q.choices} name={q.id} />
          </ul>
        </Carousel.Item>
      ))
    }
  </Carousel>
)

export default QuizCarousel