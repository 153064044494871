import React from "react";
import {Link} from "gatsby";

export function Men_BA_Divider() {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <div className="container">
        <div className="row justify-content-center milan-text-white text-center pt-1">
          <div className="col-12">
            {/* <p className="mb-0 h3">See Men's <Link to="/process/beforeafter/beforeafter_men/" className="milan-text-secondary">Before and After</Link> Photos</p> */}
            <h3 className="subpointlg mb-0">
              See Men's{" "}
              <Link to="/process/beforeafter/beforeafter_men/" className="milan-text-secondary text-decoration-underline">
              <br className="d-sm-none"/>Before and After
              </Link>{" "}
              Photos
            </h3>
          </div>
        </div>
      </div>
    </section>
    // <section className="divider-section w-100 py-4 milan-bg-navy">
    //   <div className="container ">
    //     <div className="row flex-column milan-text-white text-center">
    //       <p className="mb-0 h3">See Men's <Link to="/process/beforeafter/beforeafter_men/" className="milan-text-secondary">Before and After</Link> Photos</p>
    //       {/* <h3 className="subbpointlg mb-0">See Men's <Link to="/process/beforeafter/beforeafter_men/" className="milan-text-secondary">Before and After</Link> Photos</h3> */}
    //     </div>
    //   </div>
    // </section>
  );
}
export default Men_BA_Divider;
