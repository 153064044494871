import React from 'react'

export function ConsentMsg() {
  return (
    <small className="text-center mb-0 milan-fp lh-sm">
      I consent by electronic signature to be contacted by Milan Laser by live agent, email &amp; automatic telephone dialer for information, offers or advertisements via email/ phone call/ text message at the number &amp; email provided. No purchase necessary.
    </small>
  )
}

export default ConsentMsg