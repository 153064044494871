import React from "react"
import {
  WhatToExpect,
  ProcessReviewOne,
  YourFreeConsult,
  ProcessReviewTwo,
  PreTreatment,
  PorcessReviewThree,
  YourFirstTreatment,
  ProcessReviewFour,
  ProcessTreatments,
  PorcessReviewFive,
  Graduation,
  ProcessReviewSix,
  TouchUpTreatment
} from "."

export function ProcessSteps() {
  return (
    <>
      <WhatToExpect />
      <ProcessReviewOne />
      <YourFreeConsult />
      <ProcessReviewTwo />
      <PreTreatment />
      <PorcessReviewThree />
      <YourFirstTreatment />
      <ProcessReviewFour />
      <ProcessTreatments />
      <PorcessReviewFive />
      <Graduation />
      <ProcessReviewSix />
      <TouchUpTreatment />
    </>
  );
}
export default ProcessSteps;
