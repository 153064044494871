import { MENU_LIST } from './menu-list'
import { FACEBOOK_URL_START, MILAN_SITE_START } from '../../../utils'

// Pass in the generated currentMetro & metro objects and create a menu list for the curretn metro to be used in the navbar
export function createMenu(currentMetro) {
  MENU_LIST.forEach(item => {
    if (item.link === 'Locations') {
      // Get index of 'Facebook' subMenu item
      let f = item.subMenu.findIndex(elem => elem.link === "Facebook")
      // Get index of 'About Us' subMenu item
      let ab = item.subMenu.findIndex(elem => elem.link === "About Us")
      item.subMenu[0].link = `${currentMetro.name} Location${currentMetro.stores.length > 1 ? 's' : ''}`

      // ---------- if the current metro is a MULTIPLE locations metro ------------ //
      if (currentMetro.stores.length > 1) {
        item.subMenu[0].pathname = `/locations/${currentMetro.metroPath}/`
        item.subMenu[ab].pathname = `/locations/${currentMetro.metroPath}/#about-us`
        if (currentMetro.stores.length <= 12){
          let locations = []
          let facebook = []
          currentMetro.stores.forEach(store => {
            locations.push({
              "link": store.marketingCity,
              "pathname": `/locations/${currentMetro.metroPath}/${store.pathname}/`
            })
            facebook.push({
              "link": store.marketingCity,
              "pathname": `${FACEBOOK_URL_START}${store.platforms.facebook}`
            })
          })
          item.subMenu[0].subSubMenu = locations
          item.subMenu[f].subSubMenu = facebook
        }
      }

      // ---------- if the current metro is a SINGLE location metro ------------- //
      if (currentMetro.stores.length === 1) {
        item.subMenu[0].pathname = `/locations/${currentMetro.stores[0].pathname}/`
        item.subMenu[ab].pathname = `/locations/${currentMetro.stores[0].pathname}/#about-us`
        item.subMenu[f].pathname = FACEBOOK_URL_START + currentMetro.stores[0].platforms.facebook
      }

      // ------------ Check for Borrowed Stores ---------------------- //
      if (currentMetro.borrowed_stores && currentMetro.borrowed_stores.length > 0) {
        // Insert a new subMenu element "Nearby Locations" to item.subMenu array
        if(item.subMenu[1].link !== 'Nearby Locations') {
          item.subMenu.splice(1, 0, {
            link: 'Nearby Locations',
            pathname: currentMetro.stores.length > 1 ? `/locations/${currentMetro.metroPath}/#borrowed-stores` : `/locations/${currentMetro.stores[0].pathname}/#nearby-stores`
          })
        }
        // Create subSubMenu for the new subMenu element "Nearby Locations"
        const SUB_SUB_MENU = []
        currentMetro.borrowed_stores.forEach(store => {
          SUB_SUB_MENU.push({
            link: store.details.marketingCity,
            pathname: `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.details.pathname}/`
          })
        })
        item.subMenu[1].subSubMenu = SUB_SUB_MENU
      }
    }

    if (item.subMenu) {
      // add expanded prop and set it to false initially
      item.expanded = false
      item.subMenu.forEach(item => {
        // add expanded prop and set it to false initially
        if (item.subSubMenu) {
          item.expanded = false
        }
      })
    }
  })
  return MENU_LIST
}
export default createMenu


// You can add this to the Multiple locations to combine with the rest of stores
// Check for Borrowed Stores
// if (currentMetro.borrowed_stores && currentMetro.borrowed_stores.length !== 0) {
//   currentMetro.borrowed_stores.forEach(store => {
//     locations.push({
//       "link": store.details.marketingCity,
//       "pathname": `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.details.pathname}/`
//     })
//     facebook.push({
//       "link": store.details.marketingCity,
//       "pathname": `${FACEBOOK_URL_START}${store.details.platforms.facebook}`
//     })
//   })
// }