import React from 'react'
import { Link } from 'gatsby'

export function AreasHero() {
  return (
    <>    
      <section className="hero pt-milan-50 pb-0 py-lg-milan-100 py-xl-milan-150 milan-bg-white">
        <div className="container">
          <div className="row">
            <div className="offset-lg-5 col-lg-7 offset-xl-6 col-xl-6">
              <h1 className="milan-text-primary">Body Areas Treatable by Laser Hair Removal</h1>
                <p className="pb-3">Laser hair removal is <span className="d-none d-sm-inline"> an </span>FDA-cleared <span className="d-none d-sm-inline">treatment</span> for the removal of hair<span className="d-none d-sm-inline"> from the body</span>. <span className="d-none d-sm-inline">Unlike shaving, IPL or waxing, laser hair removal will produce amazing results without the mess and pain of outdated treatments.</span></p>
                 {/* <p className="pb-3">Laser hair removal is an FDA-cleared treatment for the removal of hair from the body. Unlike shaving, IPL or waxing, laser hair removal will produce amazing results without the mess and pain of outdated treatments.</p> */}
                <p className="">Whether it’s your <Link to="/areas/underarm/" className="milan-text-primary">underarm</Link>, <Link to="/areas/lipnchin/" className="milan-text-primary">lip, chin</Link>, <Link to="/areas/legs/" className="milan-text-primary">legs</Link>, <Link to="/areas/bikini/" className="milan-text-primary">bikini</Link>, or <Link to="/areas/for-men/" className="milan-text-primary">back/chest</Link>, we can remove hair from any area on the body. </p>
            </div>
          </div>
        </div>
        {/* bg when stacked*/}
        <div className="d-lg-none stack-hero-bg" />
      </section>
    </>
  )
}
export default AreasHero
