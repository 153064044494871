export const BA_BODY_AREAS = [
  {
    area: 'bikini',
    caption: 'Bikini',
  },
  {
    area: 'legs',
    caption: 'Legs',
  },
  {
    area: 'lip',
    caption: 'Face & Lip',
  },
  {
    area: 'men',
    caption: "Men's Areas",
  },
  {
    area: 'underarm',
    caption: 'Underarm',
  },
]