import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import PRETREATMENT_ICON from '../../../images/process/home/icon-pre-treatment.webp'

export function PreTreatment() {
  return (
    <section id="pre-treatment" className="background bg-center position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="arrow-right" src="../../../images/process/home/arrow-right.png" imgClassName="middle-icon" />
        <div className="row flex-sm-row-reverse">
          <div className="col-md-6">
            <div className="process-step milan-text-white text-center mx-auto px-sm-4 px-md-5">
              <h3 className="subpointlg">Pre-Treatment</h3>
              <p className="py-4">Before your first treatment, it’s important to avoid sun exposure. Your skin needs to be its natural skin tone in order to be safely treated, so no tanning or sunless tanner throughout your treatment.</p>
              <p className="mb-0">We’ll need you to shave the day of or the day before your first treatment, but waxing and plucking should be avoided. Come in for treatments with clean skin— no makeup, lotion, or perfume.</p>
            </div>
          </div>
          <div className="col-md-6 border-right">
            <figure className="text-center h-100 d-flex align-items-center justify-content-center pb-4 pb-md-0">
              <img alt="Milan Laser Hair Removal Process - Pre-Treatment" src={PRETREATMENT_ICON} />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PreTreatment