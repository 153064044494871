import React from "react";
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { WAXING_COST_MONTH } from '../../../utils'

export function WaxingCostPerMonth() {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
        {({ isVisible }) => (
                   <div className="container">
                   <div className="row justify-content-center milan-text-white text-center pt-1">
                     <div className="col-10 col-md-12">
                     <h3 className="subpointlg mb-0">
                         Waxing costs an average of <strong><span className="milan-text-tur width-fluid-1">${isVisible ? <CountUp end={WAXING_COST_MONTH} duration={2} /> : 0}</span></strong>
                       </h3>
                     </div>
                     <div className="col-12">
                       <p className="p-subhead mb-0 text-capitalize">per month over a lifetime.</p>
                     </div>
                   </div>
                 </div>
        )}
      </VisibilitySensor>
    </section>
  );
}
export default WaxingCostPerMonth