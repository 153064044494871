import React from 'react';
import { FooterSignup } from '.';
import BBB_LOGO from '../../images/footer/bbb.webp';

export function FooterBottom({ siteData, setShowSignUpModal }) {
  return (
    <section id='footer-bottom' className='w-100 py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 col-lg-3 text-center text-md-center mb-5 mb-lg-4'>
            <img
              src={BBB_LOGO}
              alt='Better Business Bureau Logo'
              style={{ width: '150px', height: '54.34px' }}
            />
          </div>

          <div className='col-12 col-lg-3 text-center order-lg-2 mb-5 mb-lg-4'>
            <FooterSignup
              siteData={siteData}
              setShowSignUpModal={setShowSignUpModal}
            />
          </div>

          <div className='col-12 col-lg-6 text-center order-lg-1 mb-lg-4'>
            <p className='milan-fp fw-bold mb-0'>
              Want the latest and greatest from Milan Laser?
            </p>
            <p className='milan-fp mb-0'>
              Sign up to get exclusive invitations, updates, and special offers!
              We promise to never sell your info.
            </p>
          </div>
        </div>

        <div className='row mt-4'>
          <p className='milan-fp'>
            Milan Laser Hair Removal &copy; {new Date().getFullYear()}. All
            rights reserved. ʈLaser hair removal/reduction is intended to effect
            stable, long-term, or permanent reduction through selective
            targeting of melanin in hair follicles. Individual results may vary.
            Permanent results does not necessarily imply the elimination of all
            hairs in the treatment area. *Financing options available through
            Milan Laser include a 3rd party lender/bank. Milan Laser is not a
            bank, lender, or loan broker, but provides installment payment
            plans. APRs start from 0%.
          </p>
        </div>
      </div>
    </section>
  );
}
export default FooterBottom;
