import { FORM_ACTIONS } from "./form-data"
// import { hardTrim, MILAN_SITE_START } from '../../../../utils'

export const submitConsult = (formState, siteData, succesRef, formRef) => {
  formRef.current.oid.value = process.env.GATSBY_OID
  if(formState.include.formAction === FORM_ACTIONS.selfSchedule) {
    // succesRef.current.value = `${MILAN_SITE_START}.my.site.com/s/?firstname=${formState.user.first_name}&lastname=${formState.user.last_name}&phone=%2b${hardTrim(formState.user.phoneMask)}&email=${formState.user.email}&city=${formState.metro.name}&state=${formState.metro.stateAbbrev}&success_url=${siteData.currentMetro.origin}/form/self-success-force/`
    succesRef.current.value = ''
  } else {
    succesRef.current.value = siteData.currentMetro.origin + '/form/processing/'
  }
  formRef.current.submit()
}