import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'

export function RadioBtn(props) {
  const { label, name, value, formik, ...rest } = props
  return (
    <div className="calc-radio-btn d-inline-block">
      <Field name={name} {...rest} >
        {
          ({ field }) => {
            return (
              <React.Fragment>
                <input
                  type='radio'
                  id={value}
                  className="btn-check"
                  autoComplete="off"
                  {...field}
                  value={value}
                  checked={field.value === value} />
                <label className={`${formik.values.method === value ? 'active' : ''}`} htmlFor={value}>{label}</label>
              </React.Fragment>
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default RadioBtn