import React from "react";
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

export function ShavingTimeDivider() {
  return (
    <section className="divider-section w-100 py-4 milan-bg-navy">
      <VisibilitySensor partialVisibility offset={{bottom: 50}}>
        {({isVisible}) => (
          <div className="container">
            <div className="row justify-content-center milan-text-white text-center pt-1">
              <div className="h-100 col-10 col-md-12 d-xl-flex align-items-center justify-content-center">

                <h3 className="subpointlg mb-0 d-inline">
                  Women spend{" "}
                  <strong>
                    <span className="milan-text-secondary width-fluid-1">{isVisible ? <CountUp end={131} start={60} duration={2} /> : 0}</span>{" "}
                  </strong>
                  minutes shaving{" "}
                </h3>

                {/* Inline show */}
                <p className="p-subhead d-none d-xl-inline ps-3">Each Month On Average.</p>

                {/* Stacked */}
                <p className="p-subhead d-block d-xl-none">Each Month On Average.</p>
              </div>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </section>
  );
}
export default ShavingTimeDivider