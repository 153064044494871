import React from "react"
import { Link } from 'gatsby'

export function BA_Links() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-row flex-wrap gy-3 gx-lg-2 gx-xl-4 pb-4 pb-md-0 pt-5">
      <div className="col-md-auto text-center text-lg-start mt-3 gy-3 me-sm-3"><Link className="milan-btn milan-primary-btn " to="/process/beforeafter/beforeafter_underarm/" >Underarms</Link></div>
      <div className="col-md-auto text-center text-lg-start mt-3 gy-3 me-sm-3"><Link className="milan-btn milan-primary-btn" to="/process/beforeafter/beforeafter_bikini/">Bikini</Link></div>
      <div className="col-md-auto text-center text-lg-start mt-3 gy-3 me-sm-3"><Link className="milan-btn milan-primary-btn" to="/process/beforeafter/beforeafter_lip/">Face</Link></div>
      <div className="col-md-auto text-center text-lg-start mt-3 gy-3 me-sm-3"><Link className="milan-btn milan-primary-btn" to="/process/beforeafter/beforeafter_legs/">Legs &amp; Feet</Link></div>
      <div className="col-md-auto text-center text-lg-start mt-3 gy-3 me-sm-3"><Link className="milan-btn milan-primary-btn" to="/process/beforeafter/beforeafter_men/">Men's Body Areas</Link></div>
    </div>
  );
}
export default BA_Links;
