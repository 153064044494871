import { getStore } from '../../../../utils'

export const reducer = (state, action) => {
  switch(action.type) {
    case 'formAction':
      return { 
        ...state, 
        user: { ...action.user },
        include: {
          formAction: action.formAction,
          formProgress: action.formProgress,
          leadsource: action.formAction === 'Self Schedule' ? 'Self-Schedule Site' : 'Website',
          campaignId: action.formAction === 'Self Schedule' ? '7011L000001K6qrQAC' : ''
        }
      }
    case 'nearbySelection':
      return { 
        ...state,
        metro: { ...action.metro },
        store: { ...action.store },
      }
    case 'locationsDropdown':
      if(action.salesforceValue === "") return { ...state }
      // Unclick all nearby locations if any
      action.currentMetro.stores.forEach(store => store.selected = false)
      // This will return { metro object, store object }
      const selectedLocation = getStore(action.salesforceValue, action.milanStores)
      // Check if selected store is a nearby store
      const nearbyStore = action.currentMetro.stores.find(store => store.salesforceValue === selectedLocation.store.salesforceValue)
      if(nearbyStore) nearbyStore.selected = true
      return { 
        ...state,
        metro: { ...selectedLocation.metro },
        store: { ...selectedLocation.store },
      }
    default: return { ...state }
  }
}