import React from "react";
import { Link } from 'gatsby'
import Lip_BA_Img from '../../../images/areas/lipnchin/Lip_Top-Bottom.jpg'

export function HowLipChinWorks() {
  return (
    <section id="how-lip-chin-work" className="milan-bg-tur">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 milan-bg-tur milan-text-white py-milan-50">
            <div className="row">
              <div className="col-auto">
                <h2 className="subheadlg">How Lip & Chin Laser Hair Removal Works</h2>
              </div>
              <div className="col-12 col-md-8 z-index-100 order-2 order-md-1">                
              <p className="d-none d-sm-block">
              With laser hair removal, abrasive blades and painful waxing are a thing of the past. You’ll get the <strong>permanent results</strong> you’ve always wanted without razor burn or ingrown hairs. Keep your valuable <strong>time and money</strong> by switching to laser hair removal.
              </p>
              <p className="mb-0">
                A laser pulse targets pigment in the hair follicle and destroys the root without damaging or irritating the skin.{" "}
                <span className="d-none d-sm-inline">
                  Our lasers work with cooling technology to reduce any potential discomfort.{" "}
                </span>
                With the root destroyed and the hair growth cycle disrupted, that <strong>hair will never grow again</strong>, giving you <strong>smooth, hair-free skin.</strong>{" "}
                <Link to="/process/">
                  See a demo.
                </Link>
              </p>
              <p className="d-none d-sm-block mt-3 mb-0">
                Treatments are quick, and you must shave beforehand for optimal results. Unlike electrolysis or waxing, there’s no need to deal with stubble between treatments.
              </p>
              </div>
              <div className="col-12 col-md-4 col-lg-3 col-xl-4 order-1 order-md-2 text-center z-index-100 pb-4 pb-md-0">
                <img src={Lip_BA_Img} alt="Lip and Chin Laser Hair Removal Photo, Before &amp; After" className="ba-top-bottom-img" />
              </div>
            </div>
          </div>
          <div className="col-xl-5 vrt-divider vrt-divider-reversed vrt-divider-turq" />
        </div>
      </div>
      <div className="spacer d-xl-none hr-divider hr-divider-turq-blue hr-divider-reversed"></div>
    </section>
  );
}
export default HowLipChinWorks