import React from 'react'

export function LipChinHero() {
  return (
    <section className="hero pt-milan-50 pb-0 py-md-milan-50 py-lg-milan-150 milan-bg-white">
    <div className="container">
      <div className="row">
        <div className="offset-md-5 col-md-7 offset-xl-6 col-xl-6 col-xxl-5">
          <h1 className="milan-text-primary">Laser Hair Removal For Lip & Chin</h1>
          <p>Facial hair is totally normal, but you don’t have to deal with it anymore! If you’re sick of plucking, waxing, and shaving every day, <strong>it’s time for a more permanent solution.</strong></p>
          <div className="d-none d-sm-block">
            <p>Laser hair removal&#8230;</p>
            <ul className="list-style-disc">
              <li>gets you <strong>95%+ hair-free in just seven to 10 sessions.</strong></li>
              <li>works on <strong>all skin tones!</strong></li>
              <li>targets hair on your lip, chin & cheeks <strong>without the pain and hassle</strong> of electrolysis, tweezing, or waxing.</li>
              <li>is <strong>less expensive</strong> than maintaining a lifetime of shaving and waxing.</li>
            </ul>
          </div>
          <p className='mb-0'>Get ready for the smooth, hair-free face you've always wanted.</p>
        </div>
      </div>
    </div>
    {/* bg when stacked*/}
    <div className="d-md-none stack-hero-bg" />
  </section>
  )
}

export default LipChinHero