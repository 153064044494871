export const SPECIALIZED = [
  { path: 'homedevice', text: 'At-Home Laser Hair Removal', corp: true },
  {
    path: 'active-lifestyle',
    text: 'Laser Hair Removal For Active Lifestyle',
    corp: true,
  },
  {
    path: 'medicallyrecommended/#amputee',
    text: 'Laser Hair Removal For Amputees',
    corp: true,
  },
  {
    path: 'bodybuilding',
    text: 'Laser Hair Removal For Bodybuilders',
    corp: true,
  },
  {
    path: 'areas/darkskin/',
    text: 'Laser Hair Removal For Brown Skin',
    corp: false,
  },
  {
    path: 'folliculitis',
    text: 'Laser Hair Removal For Folliculitis',
    corp: true,
  },
  {
    path: 'medicallyrecommended/#hs',
    text: 'Laser Hair Removal For Hidradenitis Suppurativa',
    corp: true,
  },
  { path: 'pcos', text: 'Laser Hair Removal For PCOS', corp: true },
  {
    path: 'medicallyrecommended/#cysts',
    text: 'Laser Hair Removal For Pilonidal Cysts',
    corp: true,
  },
  {
    path: 'trans',
    text: 'Laser Hair Removal For Transgender Clients',
    corp: true,
  },
];

export const ABOUT_MILAN_ITEMS = [
  { path: 'careers', text: 'Careers' },
  { path: 'covid', text: 'Our COVID Precautions' },
  { path: 'military', text: 'Military Discount On Laser Hair Removal' },
  { path: 'press', text: 'Press' },
  { path: 'privacy', text: 'Privacy Policy' },
  { path: 'terms', text: 'Terms and Conditions' },
  // { path: 'cookies', text: 'Cookie Preferences' },
  // { path: 'donotsell', text: 'Do Not Sell My Personal Information' },
];
