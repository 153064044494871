import React from 'react'
import { questions } from './quizConstants'
import ProgressBar from 'react-bootstrap/ProgressBar';

const QuestionsProgressBar = ({ questionIndex }) => (
  <div id="questions-progress-bar" className="progress-bar-wrapper mb-4">
    <p className="text-start">Question {questionIndex + 1} of {questions.length}</p>
    <ProgressBar now={(questionIndex + 1) * 20} />
  </div>
)

export default QuestionsProgressBar