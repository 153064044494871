import React, { useState, useEffect } from 'react'
import { usePosition, useNearbySearch } from '../../../hooks'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import { MILAN_SITE_START, CORPORATE_SITE } from '../../../utils'
import { NearbyStoreCard } from '.'
import { TextError } from '../../../components'
import { Link } from 'gatsby'

export function FindMilanNearYou({ siteData }) {
  const [selectedState, setSelectedState] = useState(siteData.currentState)
  const [selectedMetro, setSelectedMetro] = useState(siteData.currentMetro)
  const [zipCode, setZipCode] = useState({ value: '', valid: true })
  const { latitude, longitude, errorMsg, setIsGeoEnabled } = usePosition(true) // Returns user's geoCode
  const { nearbyMilanStores, loadingNearbyStores, setLoadingNearbyStores, feedback } = useNearbySearch(latitude, longitude, siteData, setIsGeoEnabled, true, zipCode)
  const [loadMore, setLoadMore] = useState(false)

  // states dropdown
  const statesDropdownHandler = event => {
    const foundState = siteData.milanStores.find(state => state.name === event.target.value)
    setSelectedState(foundState)
    setSelectedMetro(foundState.metros[0])
  }
  // metros dropdown
  const metrosDropdownHandler = event => {
    const foundMetro = selectedState.metros.find(metro => metro.name === event.target.value)
    setSelectedMetro(foundMetro)
  }

  const handleZipCode = e => {
    if (e.target.value.trim().length > 5 || !isFinite(e.target.value.trim())) {
      setZipCode({ value: e.target.value.slice(0, 5).trim(), valid: false })
    } else {
      setZipCode({ value: e.target.value.trim(), valid: true })
    }
  }

  useEffect(() => {
    if (errorMsg) setLoadingNearbyStores(false)
  }, [errorMsg])


  return (
    <div id="milan-near-you" className="container-fluid">
      {/* **************** Your Nearest Milan Location **************** */}
      <div className="row justify-content-center">
        <div className="col-12">
          <h2 className="subheadsm text-center milan-text-tur pb-2">Your Nearest Milan Location{nearbyMilanStores.length > 1 ? 's' : ''}</h2>
          {
            loadingNearbyStores && feedback === '' ?
              <div className="text-center mb-5" style={{ color: 'var(--milan-text-primary)' }}>
                <span className="milan-text-primary">Finding your nearest Milan  <Spinner animation="grow" size="sm" className="ms-2" /></span>
              </div>
              : nearbyMilanStores.length !== 0 ?
                <ul className="px-0 row gy-4 justify-content-center mt-1 pb-4">
                  {
                    nearbyMilanStores.slice(0, 4).map((store, x) => (
                      <li key={x} className="col-sm-6 col-md-5 col-lg-5 text-center">
                        <NearbyStoreCard {...{ store, zipCode }} currentMetro={siteData.currentMetro} />
                      </li>
                    ))
                  }
                  {
                    nearbyMilanStores.length > 4 && loadMore && nearbyMilanStores.slice(4, nearbyMilanStores.length).map((store, x) => (
                      <li key={x + 4} className="col-sm-6 col-md-5 col-lg-5 text-center">
                        <NearbyStoreCard {...{ store, zipCode }} currentMetro={siteData.currentMetro} />
                      </li>
                    ))
                  }
                </ul>
                :
                null
          }
          {
            nearbyMilanStores.length > 4 &&
            <div className="text-center mb-4">
              <span
                className="milan-text-primary text-decoration-underline pointer"
                onClick={() => setLoadMore(!loadMore)}>Load {loadMore ? 'less' : 'more'} locations</span>
            </div>
          }

        </div>
      </div>
      {
        errorMsg === 'User denied Geolocation' && feedback === '' &&
        <div className="row justify-content-center pb-5">
          <div className={`col-12  text-center mb-5 `}>
            <small className="d-inline-block mt-1 position-relative show-tooltip">- <em className="help milan-text-primary text-decoration-underline milan-fp">Geolocation is disabled in your browser</em> -</small>
          </div>
          <div className="col-12">
            <div className="mx-auto" style={{ maxWidth: '160px' }}>
              <Form.Control id="zipcode" name="zipcode" placeholder="Search by zipcode" className="text-center" value={zipCode.value} onChange={handleZipCode} />
              {(!zipCode.valid || feedback === 'Zipcode Failed') && <TextError>Enter a valid zipcode</TextError>}
            </div>
          </div>
        </div>
      }
      {
        feedback !== '' &&
        <div className="row justify-content-center pb-5">
          <div className={`col-12 position-relative text-center mb-4`}>
            <small className="d-inline-block mt-1"><em className="milan-text-primary">No Results!</em></small>
          </div>
          <div className="col-12">
            <div className="mx-auto" style={{ maxWidth: '160px' }}>
              <Form.Control id="zipcode" name="zipcode" placeholder="Zipcode" className="text-center" value={zipCode.value} onChange={handleZipCode} />
              {(!zipCode.valid || feedback === 'Zipcode Failed') && <TextError>Enter a valid zipcode</TextError>}
            </div>
          </div>
        </div>
      }

      {/* **************** Link to the selected/found metro area **************** */}

      {/* **************** States & Metro Areas Dropdowns **************** */}
      <div className="row justify-content-center py-5 border-top">
        <div className="col-sm-6 col-md-5 col-lg-5">
          <h6 className="pb-1 milan-text-navy">Select a State</h6>
          <div>
            <Form.Select aria-label="milan states" value={selectedState ? selectedState.name : 'Loading states...'} onChange={statesDropdownHandler} >
              {
                siteData.milanStores.length !== 0 && siteData.milanStores.map((state, x) => (
                  <option key={x} value={state.name}>
                    {state.name}
                  </option>
                ))
              }
            </Form.Select>
          </div>
        </div>
        <div className="col-sm-6 col-md-5 col-lg-5 mt-4 mt-sm-0">
          <h6 className="pb-1 milan-text-navy">Select a Metro</h6>
          <div>
            <Form.Select aria-label="current metro" value={selectedMetro ? selectedMetro.name : 'Loading metros...'} onChange={metrosDropdownHandler} >
              {
                selectedState && selectedState.metros.map((metro, x) => (
                  <option key={x} value={metro.name}>
                    {metro.name}
                  </option>
                ))
              }
            </Form.Select>
          </div>
        </div>
      </div>
      {/* **************** Link to the selected/found metro area **************** */}
      {
        selectedMetro &&
        <div className="row pt-2 justify-content-center border-top light-gray-bg">
          {
            selectedMetro.pathname !== siteData.currentMetro.pathname &&
            <div className="col-sm-6 col-md-5 col-lg-5 text-center pt-3">
              <a href={`${MILAN_SITE_START}${selectedMetro.pathname}.com`}
                className="milan-btn-sm milan-primary-btn d-block mx-auto" >Visit Our {selectedMetro.name} Website</a>
            </div>
          }
          <div className="col-sm-6 col-md-5 col-lg-5 text-center pt-3">
            <Link to={`/locations/${siteData.currentMetro.stores.length > 1 ? siteData.currentMetro.metroPath : siteData.currentMetro.stores[0].pathname}/`}
              className="milan-btn milan-primary-btn d-block mx-auto">
              {siteData.currentMetro.name} Location{siteData.currentMetro.stores.length > 1 ? 's' : null}
            </Link>
          </div>
        </div>
      }
      <div className="row justify-content-center py-4 light-gray-bg">
        <a href={`${CORPORATE_SITE}/locations/#states`} target="_blank" className="text-center milan-text-primary text-decoration-underline">See All Milan Locations</a>
      </div>
    </div>
  )
}

export default FindMilanNearYou