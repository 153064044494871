import React from "react"
import { Link } from "gatsby"

export function BrazilianHero() {
  return (
    <section className="full-section background hero">
      <div className="container">
      <h1 className="text-center milan-text-primary">Brazilian Laser Hair Removal</h1>
        <div className="row mt-5">
          <div className="col-md-6 p-4 d-none d-md-block">&nbsp;</div>
          <div className="col-md-6">
            <div className="bg-transparent-5 p-3 rounded">
              <p>Laser hair removal is the best solution for removing unwanted hair in delicate areas. Shaving around your bikini area or buttocks is tricky work and unsightly razor bumps can spoil careful grooming. You’ll never worry about ingrown hairs, razor burn or shaving bumps again after saying goodbye to your hair forever with laser hair removal.</p>
              <p>No more ripping and tearing at your hair follicles with hot wax and now you can keep blades blades away from your bikini area for good.  The Brazilian bikini area&#8212;which includes the entire bikini area, and between the buttocks (including the perirectal area)&#8212;can solve the complicated issue of reaching around with razors to catch every last stray hair. </p>
              <p className="mb-0">Our lasers are safe enough to be used in tender areas without burning or damaging the skin. The laser is operated by Authorized Candela Practitioners who are extensively trained and are overseen by medical doctors to safely utilize the laser around sensitive anatomy.</p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-lg-5">
          <div><Link className="milan-btn milan-navy-btn text-uppercase mb-3" to="/process/">The Process</Link></div>
          <div><Link className="milan-btn milan-navy-btn text-uppercase mb-3" to="/specials/">CURRENT SPECIALS</Link></div>
          <div><Link className="milan-btn milan-navy-btn text-uppercase mb-3" to="/process/beforeafter/">BEFORE/AFTER PHOTOS</Link></div>
        </div>
      </div>
    </section>
  );
}
export default BrazilianHero