import React from 'react'
import MILAN_LOGO_WHITE from '../../images/shared/Milan_Logo_White.svg'
import MILAN_LOGO_WHITE_WIDE from '../../images/shared/Milan_Logo_White_Wide.svg'

const logos = {
  white: MILAN_LOGO_WHITE,
  white_wide: MILAN_LOGO_WHITE_WIDE
}

export function MilanLogo({type, parentClasses, imgClasses}) {
  return (
    <div className={`milan-logo ${parentClasses ? parentClasses : ''}`} >
      <img className={imgClasses ? imgClasses : ''} src={logos[type]} alt="Milan Laser Logo" />
    </div>
  )
}

export default MilanLogo