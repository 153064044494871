import { useEffect, useState } from 'react';

// Returns the total count of all Milan open stores
const milanOpenStores = milanStores => {
  let openStoresTotal = 0
  milanStores.forEach(state => {
    let openInState = 0
    state.metros.forEach(metro => {
      let openInMetro = metro.stores.reduce((acc, store) => acc + store.open, 0)
      metro.openStores = openInMetro
      openInState += openInMetro
    })
    // Create a new porperty for each state
    state.openStores = openInState
    openStoresTotal += openInState
  })
  return openStoresTotal
}

// Returns an object of 2 props: 1. totalCount | 2. countToDisplay
export function useMilanOpenStores(milanStores) {
  const [openStores, setOpenStores] = useState({
    totalCount: 274,
    countToDisplay: 270
  })
  useEffect(() => {
    if(milanStores.length) {
      const allOpenStoresNum = milanOpenStores(milanStores)
      // Only display the multiples of 10
      setOpenStores({
        totalCount: allOpenStoresNum,
        countToDisplay: allOpenStoresNum - allOpenStoresNum % 10
      })
    }
  }, [milanStores])
  return openStores
}
export default useMilanOpenStores;
