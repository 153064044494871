import React from "react";
import { Link } from "gatsby";
import { useMonth } from "../../../hooks";

export function ExclusiveDivider() {
  const current_month = useMonth()
  return (
    <section className="divider-section w-100 py-3 py-lg-4 milan-bg-navy">
      <div className="container ">
        <div className="row">
          <div className="col">
          <h3 className="subpointlg milan-text-white text-center mb-0 d-lg-flex align-items-center justify-content-center">
              <span className="d-block">{current_month} Exclusive </span>
              <Link to="/specials/" className="subpointsm arranimate milan-text-secondary ms-lg-3">Free Treatment With Consult <span></span></Link>
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ExclusiveDivider