import React from 'react'
import { MILAN_PHONE, Link } from '../../../utils'
import { StaticImage } from 'gatsby-plugin-image'

export function SliderItem({ dots, link, thumbnails, store, ...rest }) {
  return (
    <div {...rest} className={`d-inline-block ${dots ? 'mt-4' : ''}`}>
      <Link to={link}
        className="location milan-text-white d-flex justify-content-center flex-column flex-lg-row">
        <div className="col-lg-4">
          <div className="w-100 text-center">
            {
              thumbnails[store.pathname] ?
                <img src={thumbnails[store.pathname]} className="img-thumbnail mx-auto" alt="" />
                : <StaticImage src="../../../images/locations/coming-soon-cam-sm.webp" imgClassName="img-thumbnail p-1" className="h-100" alt="Coming soon" />
            }
          </div>
        </div>
        <div className="col-lg-4 text-center">
          <p className="p-subhead mt-3">{store.marketingCity}</p>
          <p className="my-4">
            {store.address}<br />
            {store.addressCity === '' ? store.marketingCity : store.addressCity}, {store.stateAbbrev} {store.zipCode}
          </p>
          <p className="mb-4">{store.phone === '' ? MILAN_PHONE : store.phone}</p>
        </div>
      </Link>
    </div>
  )
}

export default SliderItem