import React from "react";

export function HowBrazilianWork() {
  return (
    <section className="full-section background secondBG">
      <div className="container">
        <h2 className="text-center subheadsm">How Brazilian Laser Hair Removal Works</h2>
        <div className="row my-4 pt-3">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="bg-transparent-5 p-3 rounded">
              <p >Brazilian hair removal takes care of all your unwanted hair in the bikini area; we’re talking your whole bikini plus between the cheeks, so your buns will never see a razor again! </p>
              <p>The Brazilian area is sensitive, but our lasers are gentle enough to still target the hair follicle and not break the surface of the skin. As the laser works, the root of the hair is permanently destroyed and you’re left with soft, smooth skin forever.</p>
              <ul className="list-style-disc mb-0">
                <li className="mb-2">There's no down-time, so you’re free to enjoy the fruits of our lasers right away!</li>
                <li className="mb-2">Unlike waxing or tweezing, you’ll shave prior to your appointment so the laser can accurately target the follicle, so you won’t be stuck growing out uncomfortable hair for weeks.</li>
                <li className="mb-2">The laser is gentle so it can treat sensitive areas safely, without losing effectiveness.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HowBrazilianWork
