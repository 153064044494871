import React, { useReducer, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  UserInputs,
  LocationsDropdownWrapper,
  ConsultCheckbox,
  consultSchema,
  reducer,
  submitConsult,
  NearbyLocations,
  ShowAddressOrLink,
  initialFormData,
  initialUserData,
  FORM_ACTIONS,
  FORM_PROGRESS,
} from '.';
import { STANDARD_INQUIRY_FORM_HANDLER } from '../../../utils';

export function NotOpen({ siteData }) {
  if (siteData.currentMetro.stores.length === 1) {
    initialFormData.store = siteData.currentMetro.stores[0];
  }
  const [formState, dispatch] = useReducer(reducer, initialFormData);

  const formRef = useRef(null);
  const succesRef = useRef(null);

  const cloudampDataRef = useRef(null);

  //used to capture the cloudamp data value and store it in localStorage
  useEffect(() => {
    // Wait for 3 seconds before accessing the value
    const timer = setTimeout(() => {
      if (cloudampDataRef.current) {
        // Set the value of localStorage to the value cloudampDataRef.current.value
        localStorage.setItem('cloudamp_value', cloudampDataRef.current.value);
      }
    }, 3000); // 3000 milliseconds = 3 seconds
    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div id='consult-form' className='hr-divider hr-divider-consult'>
      <h2 className='text-center subheadsm'>Get Notified Once We Open</h2>
      <Formik
        initialValues={initialUserData}
        validationSchema={consultSchema}
        onSubmit={() => submitConsult(formState, siteData, succesRef, formRef)}
        validateOnChange={false}
      >
        {
          // This way we can get access to all formik props
          (formik) => {
            return (
              <Form
                className='container'
                ref={formRef}
                action={STANDARD_INQUIRY_FORM_HANDLER}
                method='POST'
              >
                <input
                  ref={cloudampDataRef}
                  type='hidden'
                  name='cloudamp__data__c'
                />
                <input type='hidden' name='oid' />
                <input
                  id='success'
                  type='hidden'
                  name='retURL'
                  ref={succesRef}
                  value=''
                />
                <input
                  className='lead_source'
                  type='hidden'
                  name='lead_source'
                  id='lead_source'
                  value={formState.include.leadsource}
                />
                <input
                  type='hidden'
                  name='Campaign_ID'
                  id='campaign'
                  value={formState.include.campaignId}
                />

                <UserInputs isQuestion formik={formik} />
                {siteData.currentMetro.stores.length > 1 && (
                  <div className='locations-container'>
                    <div
                      className={`locations-wrapper mx-2 ${
                        formState.include.formProgress ===
                        FORM_PROGRESS.selectLocation
                          ? 'toggle'
                          : ''
                      }`}
                    >
                      <NearbyLocations
                        currentMetro={siteData.currentMetro}
                        dispatch={dispatch}
                      />
                      <LocationsDropdownWrapper
                        {...{ formState, dispatch, siteData }}
                      />

                      <div className='row justify-content-center mx-auto selected-location-container'>
                        <div
                          className={`col-md-10 selected-location-wrapper ${
                            formState.store.salesforceValue &&
                            formState.store.salesforceValue !== ''
                              ? 'toggle'
                              : ''
                          }`}
                        >
                          <div className='mb-0 text-center'>
                            <ShowAddressOrLink
                              formState={formState}
                              currentMetro={siteData.currentMetro}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {
                  /* Location for single store site */
                  siteData.currentMetro.stores.length === 1 && (
                    <input
                      type='text'
                      defaultValue={formState.store.salesforceValue}
                      zip={formState.store.zipCode}
                      className='d-none'
                      id='00N1L00000F9eBV'
                      name='00N1L00000F9eBV'
                      title='Location'
                    />
                  )
                }

                <div
                  id='actions-btns'
                  className='row gx-1 gy-4 justify-content-center'
                >
                  <div className='col-sm-5 col-lg-4 col-xl-3 text-center'>
                    {siteData.currentMetro.stores.length > 1 &&
                      formState.include.formProgress !==
                        FORM_PROGRESS.selectLocation && (
                        <button
                          className='milan-btn milan-navy-btn w-100'
                          onClick={() =>
                            dispatch({
                              type: 'formAction',
                              formAction: FORM_ACTIONS.question,
                              formProgress: FORM_PROGRESS.selectLocation,
                              user: formik.values,
                            })
                          }
                        >
                          SELECT A LOCATION
                        </button>
                      )}
                    {(siteData.currentMetro.stores.length === 1 ||
                      formState.include.formProgress ===
                        FORM_PROGRESS.selectLocation) && (
                      <button
                        type='submit'
                        className='milan-btn milan-red-btn w-100 mt-4'
                        onClick={() =>
                          dispatch({
                            type: 'formAction',
                            formAction: FORM_ACTIONS.question,
                            formProgress: FORM_PROGRESS.selectLocation, // if this set to '' it won't submit
                            user: formik.values,
                          })
                        }
                        disabled={!formik.isValid ? true : false}
                      >
                        {' '}
                        Submit
                      </button>
                    )}
                  </div>
                </div>
                <ConsultCheckbox />
              </Form>
            );
          }
        }
      </Formik>
    </div>
  );
}

export default NotOpen;
