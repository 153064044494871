import React from 'react'
import { Link } from 'gatsby'

export function MenOtherAreas() {
  return (
    <section id="men-other" className="pt-milan-50 py-md-milan-50 py-lg-milan-150">
    <div className="container">
      <div className="row">
        <div className="offset-md-5 col-md-7 offset-lg-6 col-lg-6">
          <h2 className="subheadlg milan-text-primary">Other Body Areas Laser Hair Removal for Men</h2>
          <p>Laser hair removal works all over the body. From your arms and legs, to chin and neck, we’ve got you covered. Other popular areas include the buttocks, underarms, bikini and feet. Milan is confident we can treat the unwanted hair on your body. If you have any questions about areas not listed, <Link to="/locations/contact/" className="milan-text-primary">feel free to contact us</Link> for more information.</p>
          <p>Laser hair removal is the best way to get rid of hair forever and Milan is your solution. <Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link></p>
        </div>
      </div>
    </div>
    {/* bg when stacked */}
    <div className="d-md-none stack-hero-bg mt-2" />
  </section>
    )
  }
  
  export default MenOtherAreas
  // <section id="men-other" className="full-section background">
  //   <div className="container">
  //     <div className="row mt-lg-4 pt-3">
  //       <div className="col-md-6 anchor-blue">
  //       <h2 className="subheadlg">Other Body Areas Laser Hair Removal for Men</h2>
  //         <p>Laser hair removal works all over the body. From your arms and legs, to chin and neck, we’ve got you covered. Other popular areas include the buttocks, underarms, bikini and feet. Milan is confident we can treat the unwanted hair on your body. If you have any questions about areas not listed, <Link to="/locations/contact/" className="milan-text-primary">feel free to contact us</Link> for more information.</p>
  //         <p>Laser hair removal is the best way to get rid of hair forever and Milan is your solution. <Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link></p>
  //       </div>
  //     </div>
  //   </div>
  // </section>