import React from 'react';
import { Formik, Form } from 'formik';
import {
  QuoteSuccess,
  bodyAreas,
  quoteInitialValues as initialValues,
  quoteSchema as validationSchema,
  submitQuote as onSubmit,
} from '.';
import { FormikControl, FormSubmitting, FormFailed } from '..';

export function QuoteForm({ siteData }) {
  initialValues.location = siteData.currentMetro.stores[0].salesforceValue;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(formValues, props) =>
        onSubmit(formValues, props, siteData.currentMetro)
      }
      validateOnChange={false}
    >
      {(formik) => {
        return (
          <Form id='quote-form' className='quote-form w-100 pb-4'>
            {formik.isSubmitting ? (
              <FormSubmitting />
            ) : formik.status === 'Success' ? (
              <QuoteSuccess />
            ) : formik.status === 'Failed' ? (
              <FormFailed />
            ) : (
              <>
                <div className='form-inputs-wrapper pt-4'>
                  <div className='row gy-3 justify-content-center mx-auto'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='first_name'
                        placeholder='First Name*'
                        type='text'
                        role='input'
                        aria-required
                      />
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='input'
                        name='last_name'
                        placeholder='Last Name*'
                        type='text'
                        role='input'
                        aria-required
                      />
                    </div>
                  </div>
                  <div className='row gy-3 justify-content-center mx-auto pt-3'>
                    <div className='col-md-5'>
                      <FormikControl
                        control='phoneInput'
                        name='phone'
                        placeholder='Phone Number*'
                        type='tel'
                        role='input'
                        aria-required
                      />
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='emailInput'
                        name='email'
                        placeholder='Your Email*'
                        role='input'
                        aria-required
                      />
                    </div>
                    <div className='col-md-5'>
                      <FormikControl
                        control='selectLocation'
                        name='location'
                        label='Location'
                        milanStores={siteData.milanStores}
                      />
                    </div>
                  </div>
                  <h6 className='text-center mt-3 mt-sm-4 mt-lg-5'>
                    Please select up to 3 areas you’re interested in.
                  </h6>
                  <div className='row justify-content-center mt-4 pt-1 checkboxes'>
                    <ul className='col-sm-10 row'>
                      <FormikControl
                        control='areasCheckboxes'
                        name='bodyAreas'
                        options={bodyAreas}
                        formik={formik}
                      />
                    </ul>
                  </div>
                  <div className='row justify-content-center mt-4'>
                    <div className='col-md-10 row'>
                      <h6 className='text-center'>
                        Other Areas{' '}
                        <span className='d-none d-md-inline'>
                          (we treat almost any area of the body)
                        </span>
                        :
                      </h6>
                      <FormikControl
                        control='textarea'
                        name='description'
                        placeholder="Describe any other areas you're interested in."
                        rows='1'
                      />
                    </div>
                  </div>
                </div>
                <div
                  id='actions-btns'
                  className='row justify-content-center my-3 col-lg-10 m-auto'
                >
                  <div className='col-sm-6 col-lg-4 text-center'>
                    <button
                      className='milan-btn milan-red-btn w-100'
                      type='submit'
                      disabled={!formik.isValid}
                    >
                      Request a Quote
                    </button>
                  </div>
                  <p className='text-center mt-2 mb-0'>
                    <small>
                      Terms &amp; Conditions may apply. See store for more
                      details.
                    </small>
                  </p>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default QuoteForm;
