import React from "react"
import { BA_Links, SplitComp } from ".";

export function BAHeroLayout({children, area}) {
  return (
    <section className="background hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-xl-7 m-auto">
            {children}
          </div>
        </div>
        <SplitComp area={area} />
      </div>
      <BA_Links />
    </section>
  );
}
export default BAHeroLayout;
