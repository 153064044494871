import React from 'react'
import { Link } from 'gatsby'

const QuizIntro = ({ startQuiz }) => (
  <div>
    <h6>Top 5 Questions to Answer Before You Begin Laser Hair Removal</h6>
    <p className="mt-4 pb-3">When performed correctly, laser hair removal is a <Link to="/process/compare/" className="milan-text-primary">very effective method for removing unwanted hair</Link>. But are you a good candidate for laser hair removal? The answers to these common questions will help determine if you qualify.</p>
    <button className="milan-btn-sm milan-primary-btn" onClick={startQuiz}>Take Quiz</button>
  </div>
)

export default QuizIntro