import React from "react";
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { WAXING_COST_YEAR } from '../../../utils'

export function WaxingCostPerYear({ globalData }) {
  return (
      <section className="divider-section w-100 py-4 milan-bg-navy overflow-hidden">
      <VisibilitySensor partialVisibility offset={{ bottom: 50 }}>
        {({ isVisible }) => (
          <div className="container">
            <div className="row justify-content-center milan-text-white text-center pt-1">
              <div className="col-10 col-md-12">
              <h3 className="subpointlg mb-0">
                Waxing costs <br className="d-md-none"/><span className="milan-text-tur width-fluid-1"><strong>${isVisible ? <CountUp end={globalData.waxing_cost_per_year ? globalData.waxing_cost_per_year : WAXING_COST_YEAR} duration={2} /> : 0},000</strong></span>
              </h3>
              </div>
              <div className="col-12">
                <p className="p-subhead mb-0 text-capitalize">over a lifetime on average.</p>
              </div>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </section>
  );
}
export default WaxingCostPerYear