import React, { useState, useEffect } from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { MILAN_SITE_START } from '../../../utils'
import { useThumbnails } from '../../../hooks'
import { SliderItem } from '.'

export function NearbyStores({ currentStore, currentMetro }) {
  const thumbnails = useThumbnails(currentMetro)
  const [sliderSettings, setSliderSettings] = useState({
    dots: currentMetro.stores.length > 2 ? true : false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    appendDots: dots => (
      <div >
        <ul className="m-0"> {dots} </ul>
      </div>
    )
  })

  const nearbyStoresArr = currentMetro.stores.filter(store => store.marketingCity !== currentStore.marketingCity)

  useEffect(() => {
    if (currentMetro.borrowed_stores && currentMetro.borrowed_stores.length !== 0) {
      setSliderSettings({
        ...sliderSettings,
        dots: (currentMetro.stores.length + currentMetro.borrowed_stores.length) > 2 ? true : false
      })
    }
  }, [currentMetro.borrowed_stores])

  return (
    <section id="nearby-stores" className="py-5 nearby-locations-carousel">
      <div className="container">
        <h2 className="text-center milan-text-white subheadlg mb-0">Nearby Locations</h2>
        <Slider {...sliderSettings} className="location-wrapper overflow-hidden">
          {
            nearbyStoresArr.map((nearbyStore, x) => (
              <SliderItem
                key={x}
                store={nearbyStore}
                thumbnails={thumbnails}
                dots={sliderSettings.dots}
                link={`/locations/${currentMetro.metroPath}/${nearbyStore.pathname}/`}
              />
            ))
          }
          {
            (currentMetro.borrowed_stores && currentMetro.borrowed_stores.length !== 0) && currentMetro.borrowed_stores.map((borrowedStore, x) => (
              <SliderItem
                key={x}
                store={borrowedStore.details}
                thumbnails={thumbnails}
                dots={sliderSettings.dots}
                link={`${MILAN_SITE_START}${borrowedStore.metro.pathname}.com/locations/${borrowedStore.metro.metroPath}/${borrowedStore.details.pathname}/`}
              />
            ))
          }
        </Slider>
      </div>
    </section>
  )
}
export default NearbyStores;
