import React from 'react'

// Error Message Alert Component
export function TextError({children}) {
  return (
    <div className="text-error">
      {children}
    </div>
  )
}

export default TextError
