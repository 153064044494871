import axios from 'axios'
import { NETLIFY_FUNCTIONS_PATH } from '../../../utils'

// Kickbox email verification
export const validateEmail = async email => {
  if (email) {
    return axios.get(`${NETLIFY_FUNCTIONS_PATH}kickbox?email=${email}`)
      .then(response => {
        if (response.status === 200) {
          if (!response.data.valid) {
            return response.data.error
          }
        }
      }).catch(err => {
        console.error(err)
      })
  }
}

export default validateEmail