import React from "react";
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

export function LHRIdealForBikini() {
  return (
    <section className="full-section background thirdBG">
      <div className="container">
        <h2 className="text-center subheadsm bg-sm-transparent-5 bg-lg-transparent p-3 p-lg-0 rounded">Why Laser Hair Removal is Ideal for&nbsp;Bikini&nbsp;Area</h2>
        <div className="row mt-lg-4 pt-3">
          <div className="col-md-6"></div>
          <div className="col-md-6 mb-3">
            <div className="bg-transparent-5 bg-lg-transparent p-3 p-lg-0 rounded anchor-blue">
              <p>The delicate skin around your bikini deserves to be treated kindly &#8212; don’t torture it with blades and molten wax!</p>
              <ul className="list-style-disc">
                <li className="mb-2"><strong>Beach Ready. Always.</strong> Last minute appointments and the waxing-waddle will be a distant memory. <span className="d-none d-sm-block">You’ll always be prepared for swimsuit season after laser hair removal.</span></li>
                <li className="mb-2"><strong>Permanent Results.</strong> No more shaving or painful waxing. Ever. <span className="d-none d-sm-block">Your smooth skin is here to stay.</span></li>
                <li className="mb-2"><strong>Lifetime Guarantee</strong>. Have a rogue hair that didn’t get the memo about being hair free? <span className="d-none d-sm-block">No problem, you’re covered with Milan! </span>Come in and we’ll take care of it, no questions asked, and no additional cost.</li>
                <li className="mb-2"><strong>Gentle Hair Removal.</strong> <span className="d-none d-sm-block">Because the laser targets the hair follicle, your skin doesn’t suffer through the abrasive action that razors, depilators, or hot waxes can cause so you don’t risk breaking the protective surface of the skin.</span> <span className="d-sm-none">The laser targets the hair follicle and doesn’t break or harm the protective surface of the skin.</span></li>
              </ul>
              <p className="d-none d-sm-block">Laser hair removal is the best way to remove hair from your bikini area. It’s the safe, and 100% effective process that guarantees smooth skin.</p>
              <Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link>
            </div>
          </div>
        </div>
        <div className="row d-sm-none">
          <div className="col-lg-6 text-center">
            <Link to="/process/beforeafter/">
              <StaticImage src="../../../images/shared/BA-btn-mobile.webp" alt="" />
            </Link>
          </div>
          <div className="col-md-6 text-center">
            <Link to="/specials/">
              <StaticImage src="../../../images/shared/specials-btn-mobile.webp" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LHRIdealForBikini
