import React from 'react'
import { Link } from "gatsby"

export function LHRIdealForUnderarm() {
  return (
    <section id="lhr-ideal-underarm" className="pt-milan-50 py-md-milan-50 py-lg-milan-150">
      <div className="container">
        <div className="row">
          {/* <div className="offset-md-5 col-md-7 offset-lg-6 col-lg-6"> */}
          {/* <div className="offset-md-5 col-md-7 offset-lg-5 col-lg-7 offset-xl-6 col-xl-6 offset-xxl-6 col-xxl-5"> */}
          <div className="offset-md-5 col-md-7 offset-lg-6 col-lg-6 offset-xl-6 col-xl-6 offset-xxl-6 col-xxl-5">
            <h2 className="subheadlg milan-text-primary">
              Why Laser Hair Removal <br className="d-md-none" />is Ideal for Underarms
            </h2>
            <p>Laser hair removal for your underarms is the best way to remove unwanted hair.</p>
            <ul className="list-style-disc">
              <li className="mb-2"><strong>It’s always T-Shirt season.</strong> No more razors or long-sleeves because you forgot to shave. <span className="d-none d-sm-block">You’re always ready to go!</span></li>
              <li className="mb-2"><strong>Comfortable Treatments.</strong> Unlike a painful wax, you’ll comfortably remove your underarm hair and you won’t see a single tweezer or sticky substance in sight.</li>
              <li className="mb-2"><strong>Never Shave Again.</strong> With Milan’s exclusive <Link to="/specials/#guarantee" className="milan-text-primary">Unlimited Package™</Link>, your results are guaranteed for life. As in, forever and beyond, at no additional cost. We believe in our lasers, and we’re willing to prove it by offering unlimited sessions with every package.</li>
            </ul>
            <p className="d-none d-sm-block">Laser hair removal, because your underarms should be soft, smooth and one less chore for you to worry about every day! Permanently smooth skin is in your future.</p>
            <Link to="#consult" className="milan-text-primary">Schedule your <strong>free consultation</strong> to learn more about laser hair removal today!</Link>
          </div>
        </div>
      </div>
      {/* bg when stacked */}
      <div className="d-md-none stack-hero-bg" />
    </section>
  )
}

export default LHRIdealForUnderarm