import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';
// import { STORES_LOCAL } from '../utils'

export function useMilanStores() {
  const [milanStores, setMilanStores] = useState([]);
  const collectionRef = collection(
    db,
    process.env.GATSBY_FIREBASE_MILAN_STORES_COLLECTION
  );

  useEffect(() => {
    const getMilanStores = async () => {
      try {
        const collectionData = await getDocs(collectionRef);
        const fetchedData = collectionData.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMilanStores(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };
    getMilanStores();
  }, []); // Danger: DO NOT add milanStores as a dependency
  return milanStores;
}
export default useMilanStores;
