import React from 'react'

export function NearbyLocations({ currentMetro, dispatch }) {
  const handleClick = store => {
    currentMetro.stores.forEach(store => store.selected = false)
    store.selected = true
    dispatch({
      type: 'nearbySelection',
      metro: {
        name: currentMetro.name,
        pathname: currentMetro.pathname,
        stateAbbrev: currentMetro.stateAbbrev
      },
      store: store
    })
  }
  return (
    <div className="row justify-content-center pt-lg-2">
      <div className="col-xl-10">
        <h3>Select A Location Near You</h3>
        <ul className="row g-2 g-sm-3 ps-0">
          {
            currentMetro.stores.map((store, x) => (
              <li key={x} className="col-sm-6 col-md-4 col-xl-3">
                <div
                  className={`milan-btn milan-store-btn ${store.selected === true ? 'selected' : ''}`}
                  onClick={() => handleClick(store)}>
                  {store.marketingCity}
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default NearbyLocations