module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBCFXVLQ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Milan Laser Hair Removal","short_name":"Milan Laser","description":"Laser Hair Removal | Milan Laser is one of the nation’s leading laser hair removal companies. We are committed to providing our clients with world-class customer service and permanent results. Our physicians and highly-trained medical staff utilize ongoing medical research to ensure that all treatments are performed safely and are tailored to give every client the best results possible. Our goal is to provide 100% customer satisfaction to every client and we will do everything we can to ensure our clients are happy with their experience as well as their results.","start_url":"/","background_color":"#F2FAFD","theme_color":"#F2FAFD","display":"standalone","icon":"/opt/build/repo/theme-2023/src/images/favicon.webp","icons":[{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-192x192.webp","sizes":"192x192","type":"image/png"},{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-256x256.webp","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d86e0a17ee6e0d2e70fbdb8db6f8086c"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../theme-2023/gatsby-browser.js'),
      options: {"plugins":[],"siteData":{"pathname":"newhaven","metroPath":"newhaven","origin":"https://milanlasernewhaven.com","google_verification":"","state":"Connecticut","bing_id":"","stores":[{"open":true,"place_id":"Ei01NTQgQm9zdG9uIFBvc3QgUmQgIzEwLCBPcmFuZ2UsIENUIDA2NDc3LCBVU0EiHhocChYKFAoSCZ-bA5m3deiJETgidfNWPSSAEgIxMA","openDate":{"seconds":1709013600,"nanoseconds":0},"description":{"line_one":" off Boston Post Rd. in the Orange Meadows shopping center","line_two":" next to The UPS Store and I Spa Inc"},"salesforceValue":"New Haven (Milford)","hero":{"reversed":false,"position":"center"},"maps":{"apple":"https://maps.apple.com/?address=554%20Boston%20Post%20Rd,%20Orange,%20CT%20%2006477,%20United%20States&auid=6427822968362756812&ll=41.251097,-73.019344&lsp=9902&q=Milan%20Laser%20Hair%20Removal","google":"https://maps.app.goo.gl/X1aY5VsJcZvCop6x5"},"zipCode":"06477","rating":null,"state":"Connecticut","phone":"475-480-4106","geo":{"lat":"41.251123","long":"-73.019346"},"timezone":"Eastern","clinicName":"","with_pro_images":true,"pathname":"milford","cm_pardot_file":"301 - 325","reviewCount":null,"marketingCity":"Milford","platforms":{"yelp":"orange","reviews":{"list_token":"7bHt1UlR6n4Gu5BRsL0q4HqnLAhnx6zsGomdD9EXMKsydlwHmA","slide_token":"D598PTIOG7sbNcGbyXWsbajRGK1QYB5j3nz1RfI6WmtoY2I1Dz"},"facebook":"milford","google":"https://g.co/kgs/T7fciH3"},"address":"554 Boston Post Road #10","stateAbbrev":"CT","store_email":"newhavenmilford@milanlaser.com","addressCity":"Orange"}],"stateAbbrev":"CT","openStores":1,"gtag_id":"GTM-NBCFXVLQ","google_analytics_id":"","name":"New Haven","grandOpening":true}},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
