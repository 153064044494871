import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

export function WhyMilan() {
  return (
    <section id="why-mlhr" className="background unlimited-package-bg">
      <div className="container milan-py">
        <div className="row">
          <div className="col offset-lg-1 offset-xl-2">
            <div className="row">
              <div className="col">
                <h2 className="subheadlg milan-text-primary">Why Milan Laser Hair Removal?</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="pe-md-4">
                  <p>We’re the experts in laser hair removal, it’s all we do. <em>PLUS</em> we include our <Link className="milan-text-primary" to="/specials/#guarantee">Unlimited Package™</Link> with every body area.</p>
                  <p>Milan is the largest laser hair removal company in the nation and the only one that offers the Unlimited Package: unlimited treatments for life at no additional cost.  While the average person needs 7 to 10 treatments to be 95%+ hair free, with us you’ll always be covered no matter how many treatments you need.</p>
                  <p>The bottom line: <strong>You’ll never pay for hair removal on the same body area again.</strong></p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-3">
                <div className="text-center text-md-start my-4 my-lg-5">
                  <StaticImage src="../../images/shared/Unlimited-Package.png" style={{maxWidth: '200px'}} alt="Exclusively at Milan Laser The Unlimited Package Lifetime Guarantee No Surprise Costs" />
                </div>
              </div>
            </div>
            <div className="row mt-3 gy-3">
              <div className="col-md-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="/process/beforeafter/">Before &amp; After</Link>
              </div>
              <div className="col-md-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="/process/">Our Technology</Link>
              </div>
              <div className="col-md-auto text-center text-lg-start">
                <Link className="milan-btn milan-primary-btn" to="/process/compare/">Hair Removal Techniques</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background spacer d-lg-none"></div>
    </section>
  );
}
export default WhyMilan