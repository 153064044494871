import React from 'react'

export function SubmitBtn({ formik }) {
  return (
    <button
      className="milan-btn milan-red-btn w-100" type="submit"
      disabled={!formik.isValid}> Submit</button>
  )
}

export default SubmitBtn