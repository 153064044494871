import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export function ProcessReviewOne() {
  return (
    <section className="position-relative">
      <div className="container-lg">
        <StaticImage className="d-none d-md-block middle-icon position-absolute top-50 start-50 translate-middle" alt="quote-circle" src="../../../images/process/home/quote-circle.png" imgClassName="middle-icon" />
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center text-with-quotes">
            <figure className="text-center px-2 mb-0 limited-width">
              <blockquote>I loved that they had the presentation in front of me and that there was absolutely no pressure to buy.</blockquote>
              <figcaption> <strong>- Laura Funk</strong></figcaption>
            </figure>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center milan-py-lg">
            <StaticImage className="reviewer-img" alt="Laura Funk Review" src="../../../images/process/home/laura-funk.webp" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProcessReviewOne