import { useState, useEffect } from 'react';
import { storage } from "../firebase/firebase"
import { ref, getDownloadURL } from 'firebase/storage'
import { hardTrim } from '../utils'

export function useThumbnails(currentMetro) {
  const storagePath = `stores/${hardTrim(currentMetro.state).toLowerCase()}/${currentMetro.pathname}/`
  const [thumbnails, setThumbnails] = useState({})

  useEffect(() => {
    const getThumbnails = () => {
      let tempThumbnails = {}

      currentMetro.stores.forEach(store => {
        if (!thumbnails[store.pathname]) { // To avoid repetition on Rerender
          const path = `${storagePath}/${store.pathname}/store/${hardTrim(store.marketingCity)}_Thumbnail.webp`
          getDownloadURL(ref(storage, path)).then(url => {
            tempThumbnails[store.pathname] = url
            setThumbnails({
              ...tempThumbnails,
              [store.pathname]: url
            })
          }).catch(err => {
            console.log(`${hardTrim(store.marketingCity)}_Thumbnail img doesn't exist`)
          })
        }
      })

      if (currentMetro.borrowed_stores && currentMetro.borrowed_stores.length !== 0) {
        currentMetro.borrowed_stores.forEach(store => {
          if (!thumbnails[store.details.pathname]) {
            const path = `stores/${store.state.pathname}/${store.metro.pathname}/${store.details.pathname}/store/${hardTrim(store.details.marketingCity)}_Thumbnail.webp`
            getDownloadURL(ref(storage, path)).then(url => {
              tempThumbnails[store.details.pathname] = url
              setThumbnails({
                ...tempThumbnails,
                [store.details.pathname]: url
              })
            }).catch(err => {
              console.log(`${hardTrim(store.details.marketingCity)}_Thumbnail img doesn't exist`)
            })
          }
        })
      }
    }
    getThumbnails()
  }, [currentMetro.borrowed_stores])
  return thumbnails
}
export default useThumbnails