export const shaving_waxing_cost = {
  'Arms': {
    shaving: 54,
    waxing: 462
  },
  'Back': {
    shaving: 54,
    waxing: 693
  },
  'Bikini': {
    shaving: 81.9,
    waxing: 429
  },
  'Brazilian': {
    shaving: 81.9,
    waxing: 616
  },
  'Chest': {
    shaving: 81.9,
    waxing: 363
  },
  'Legs': {
    shaving: 131.4,
    waxing: 825
  },
  'Lip & Chin': {
    shaving: 162,
    waxing: 650
  },
  'Underarms': {
    shaving: 131.4,
    waxing: 462
  },
}