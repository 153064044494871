import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { LocationsDropdown } from '../shared'
import { TextError } from '.'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAsterisk} from "@fortawesome/free-solid-svg-icons";

export function SelectLocation(props) {
  const { label, name, milanStores, ...rest } = props
  return (
    <div>
      {label && <label htmlFor={name}>{label} <sup><FontAwesomeIcon icon={faAsterisk} /></sup></label>}
      <Field as="select" id={name} name={name} {...rest} className="form-select">
        <LocationsDropdown milanStores={milanStores} />
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default SelectLocation