import React from 'react'
import { LocationsDropdown } from '../shared'

export function LocationsDropdownWrapper({formState, dispatch, siteData}) {
  return (
    <div id="locations-dropdown" className="row justify-content-center my-3">
      <div className="col-md-6 col-lg-4">
        <h3>Or select a location from the list</h3>
        <select
          value={formState.store.salesforceValue} onChange={(e) => dispatch({
            type: 'locationsDropdown',
            salesforceValue: e.target.value,
            milanStores: siteData.milanStores,
            currentMetro: siteData.currentMetro
          })}
          className="form-select" id="00N1L00000F9eBV" name="00N1L00000F9eBV" title="Location">
          <LocationsDropdown milanStores={siteData.milanStores} />
        </select>
      </div>
    </div>
  )
}

export default LocationsDropdownWrapper