import React from 'react'
import { Link } from 'gatsby'

export function SignUpSuccess() {
  return (
    <center className="px-3 lh-base">
      <p className="h1 milan-text-primary">ALL SET!</p>
      <p className="h5 mt-3 mb-3">You're signed up for our latest specials.</p>
      <p className="h6 lh-base">If you have any questions in the meantime, <br /> <Link className="milan-text-primary" to="/locations/contact/">please contact us</Link>.</p>
      <p className="mb-0 mt-3">Thanks,</p>
      <p className="mb-0">Milan Laser</p>
    </center>
  )
}

export default SignUpSuccess
