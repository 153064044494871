import React from 'react';
import { MultipleStores, SingleStore, NotOpen } from '.';
import './Consult.css';

export function Consult({siteData}) {
  if(siteData.currentMetro.stores.length > 1 && siteData.currentMetro.openStores > 0) return <MultipleStores siteData={siteData} />
  else if(siteData.currentMetro.stores.length === 1 && siteData.currentMetro.openStores > 0) return <SingleStore siteData={siteData} />
  else return <NotOpen siteData={siteData} />
}
export default Consult;
