import React from 'react';
import { Formik, Form } from 'formik';
import { FormikControl } from '..';
import { FormSubmitting, FormFailed, SignUpConsentMsg } from '..';
import {
  faqsInitialValues as initialValues,
  faqsSchema as validationSchema,
  submitFaqsForm as onSubmit,
  FaqsSuccess,
} from '.';

const askNewQuestionHandler = (formik) => {
  formik.setStatus('New question');
  formik.setFieldValue('description', 'Ask a new question');
};

export function FaqsForm({ siteData }) {
  initialValues.location = siteData.currentMetro.stores[0].salesforceValue;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(user, props) => onSubmit(user, props, siteData.currentMetro)}
      validateOnChange={false}
    >
      {(formik) => {
        return (
          <Form className='w-100 pb-4'>
            {formik.isSubmitting ? (
              <FormSubmitting />
            ) : formik.status === 'Success' ? (
              <FaqsSuccess
                askNewQuestionHandler={askNewQuestionHandler}
                formik={formik}
              />
            ) : formik.status === 'Failed' ? (
              <FormFailed />
            ) : (
              <>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='mb-2'>
                      <FormikControl
                        control='textarea'
                        name='description'
                        placeholder='Your Question'
                        rows={
                          siteData.currentMetro.stores.length > 1 ? '7' : '5'
                        }
                        role='input'
                        aria-required
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='row gx-2'>
                      <div className='col-md-6'>
                        <div className='mb-2'>
                          <FormikControl
                            control='input'
                            name='first_name'
                            placeholder='First Name*'
                            type='text'
                            role='input'
                            aria-required
                          />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='mb-2'>
                          <FormikControl
                            control='input'
                            name='last_name'
                            placeholder='Last Name*'
                            type='text'
                            role='input'
                            aria-required
                          />
                        </div>
                      </div>
                    </div>
                    <div className='mb-2'>
                      <FormikControl
                        control='phoneInput'
                        name='phone'
                        placeholder='Phone Number*'
                        type='tel'
                        role='input'
                        aria-required
                      />
                    </div>
                    <div className='mb-2'>
                      <FormikControl
                        control='emailInput'
                        name='email'
                        placeholder='Your Email*'
                        role='input'
                        aria-required
                      />
                    </div>
                    {siteData.currentMetro.stores.length > 1 && (
                      <div>
                        <FormikControl
                          control='selectLocation'
                          name='location'
                          milanStores={siteData.milanStores}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='d-flex mt-2 mt-md-0'>
                    <FormikControl
                      control='mailchimp'
                      name='updates'
                      mailchimpID='mailchimp-faqs'
                    />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12 text-center'>
                    <button
                      className='milan-btn milan-red-btn w-100 send-question-btn'
                      type='submit'
                      disabled={!formik.isValid}
                      style={{ maxWidth: '300px' }}
                    >
                      SEND QUESTION{' '}
                    </button>
                  </div>
                  <div className='col-12'>
                    <small className='milan-fp lh-sm'>
                      <SignUpConsentMsg />
                    </small>
                  </div>
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default FaqsForm;
